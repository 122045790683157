<template>
  <div id="main">
    <!-- 顶部 -->
    <div class="main-nav-box">
      <div class="main-nav">
        <a href="/home">
          <img class="PC_home_icon" :src="PC_home" lazy />
        </a>
        <div class="small-language-box1" @click.stop="isShow = !isShow">
          <span class="small-language2">Language(한국어)</span>
          <img class="hamburger-img" :src="PC_line" lazy />
        </div>
        <div class="hamburger-mask" v-if="isShow">
          <div class="main-smallnav">
            <a class="app-menu-list" href="/exploreallpass/zh/">
              <div class="smallnav-select-item"><span class="main-nav-content">简体中文</span>
              </div>
            </a>
            <a class="app-menu-list" href="/exploreallpass/zh_tw/">
              <div class="smallnav-select-item"><span class="main-nav-content">繁體中文</span></div>
            </a>
            <a class="app-menu-list" href="/exploreallpass/en/">
              <div class="smallnav-select-item"><span class="main-nav-content">ENGLISH</span></div>
            </a>
            <a class="app-menu-list" href="/exploreallpass/ko/">
              <div class="smallnav-select-item"><span class="main-nav-content smallnav-select-item-active">한국어</span></div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 轮播 -->
    <div class="main-swiper">
      <div>
        <el-carousel class="bannerImg-box" trigger="click" :interval="7000">
          <el-carousel-item v-for="(item,index) in bannerImg" :key="index">
            <img :src="item" class="slide-img1" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 有效期探游卷 -->
    <div class="main-travel ">
      <div class="train-box">
        <div class="app-container">
          <div class="col-md-12 travel-item-box">
            <img class="tracks-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-1.png" lazy />
            <div class="travel-item-md6" v-for="(item,i) in ticketArr" :key="i" @click="change(item)">
              <div :class="[active===item.id ? 'travel-item1' : 'travel-item']">
                <img class="lazy travel-item-img" :src="item.img" alt="" srcset="">
                <p class="travel-right">유효일
                  <span class="travel-right-day">
                    <span :style="{'color':item.fontcolor}">{{item.validityDay}}</span>&nbsp;일</span>
                </p>
                <!-- <p class="travel-title">{{item.ticketName}}</p> -->
                <p class="travel-detail" v-for="(item ,index) in splitBatName(item.describe)" :key="index">{{item}}</p>
                <p class="travel-price" :style="{'color':item.fontcolor}">￥{{item.price}}</p>
              </div>
            </div>
          </div>
        </div>
        <img class="lazy train-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-1.png" alt="">
        <img class="lazy train-img2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-1.png" alt="">
      </div>
    </div>
    <!-- 套餐内容 -->
    <div class="main-package">
      <div class="app-container">
        <div class="package-imgBox">
          <img class="lazy package-train-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-2.png" alt="">
          <img class="lazy package-point-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-1.png" alt="">
        </div>
        <div class="col-md-12  package-content-box">
          <div class="package-content flex-arround">
            <img class="lazy package-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" alt="">
            <p class="package-title">ALL PASS 패키지 콘텐츠</p>
            <img class="lazy package-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" alt="">
          </div>
        </div>
        <div class="col-md-12">
          <div class="package-riding-content">
            <div class="riding-box">
              <div class="riding-left">
                <img class="lazy riding-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/riding-2.png" alt="">
                <img class="lazy riding-copyright" :src="rid_copy" alt="">
              </div>
              <div class="riding-right">
                <div class="right-title-box">
                  <p class="riding-right-title">JR 승차권</p>
                  <p class="riding-right-title2">지정석 탑승가능</p>
                </div>
                <p class="riding-right-title3">간사이 공항 특급 하루카 편도 티켓 <br>(간사이 공항역 → 교토역)</p>
                <img :src="ticket_zhu2" alt="" style="width:362px ;">
              </div>
            </div>
            <img :src="ticket_zhu" style="width: 76%;margin-left: 20px;margin-top: 5px;" alt="">
            <div class="riding-bottom" :class="[active===2 ? 'riding-bottom2' : '']">
              <div class="riding-bottom-1" :class="[active===2 ? 'riding-bottom-1-1' : '']">
                <p class="riding-bottom-t" :class="[active===2 ? 'riding-bottom-t1' : '']" v-for="(item ,index) in splitBatName(planName)" :key="index">{{item}}</p>
                <p class="riding-bottom-p">￥{{planPrice}}</p>
              </div>
              <div class="riding-bottom-2" v-if="planName2 && planPrice2" :class="[active===2 ? 'riding-bottom-2-1' : '']">
                <p class="riding-bottom-t" v-for="(item ,index) in splitBatName(planName2)" :key="index">{{item}}</p>
                <p class="riding-bottom-p">￥{{planPrice2}}</p>
              </div>
              <div class="riding-bottom-3" v-if="planName3 && planPrice3">
                <p class="riding-bottom-t">{{planName3}}</p>
                <p class="riding-bottom-p">￥{{planPrice3}}</p>
              </div>
              <!-- <div class="riding-bottom-4" :class="[active===2 ? 'riding-bottom-42' : '']">
                            <p>※仅一次有效</p>
                            <p>※也能够于天王寺•新大阪站下车</p>
                        </div> -->
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="package-universal-content">
            <div class="universal-left">
              <p class="universal-left-1">유니버설 스튜디오 재팬<br>1 DAY 스튜디오 패스</p>
              <!-- <p class="universal-left-2">无法想象的感动在影城等待您！</p> -->
              <div class="universal-right">
                <p class="universal-left-3">
                  <span>어른들도 확실하게 만족할 수 있는 세계 최고 퀄리티의 엔터테인<br>먼트가 한가득!<br>테마파크 업계 최고 영예'Thea상'을 수상한 세계 최초*의 마리오<br>에어리어와
                    '마리오 카트: 쿠파의 도전장™'은 물론,'더 플라잉 다<br>이너소어'를 필두로 한 엄청난 자극의 어트랙션,위저딩 월드 오브<br>위저딩 월드 오브 해리 포터™와 미니언 등 인기
                    절정 브랜드 어트<br>랙션등,
                    최고의 감동과 흥분을 선사하는 파크에서 힐링해 보세요!</span>
                </p>
                <img class="lazy universal-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/universal-zh1.jpg" data-url="https://qbcdn.jtrchina.com/tripelletexploreallpass/universal-zh1.jpg" alt="">
              </div>
              <div class="universal-left-4">
                <p>*닌텐도 브랜드의 캐릭터 및 세계관을 테마로 한 시설을 대상으로 합동회사 유에스제이 조사</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 观光设施共通劵 -->
        <div class="col-md-12 " style="display: flex;justify-content: center;">
          <div class="facilities-content ">
            <div class="facilities-borderWhite">
              <div class="facilities-box flex-arround">
                <img class="lazy facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" alt="">
                <p class="facilities-title">{{ticketDescribe}}</p>
                <img class="lazy facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" alt="">
              </div>
              <div class="facilities-attention ">
                <p>1. 임시휴업인 경우가 있습니다. 이용당일 반드시 해당 시설의 최신정보 및 영업시간을 확인해 주세요.</p>
                <p>2. 한 시설에 입장한 후(QR코드 스캔 후) 다음 시설을이용하기 위해서는 2시간이 경과한 후부터 가능합니다.</p>
                <p>3. 같은 시설을 두 번 입장하실 수 없습니다.</p>
              </div>
              <div class="row area-box">
                <div class="area-md3" v-for="(item,i) in md3" :key="i" @click="activeArea(item)">
                  <div class="all-area" :class="[activeArr.includes(item.id)? 'area-active' : '']">
                    {{item.name}}
                  </div>
                </div>
                <div class="area-md3" v-show="active === 1" v-for="(item,i) in md4" :key="i" @click="activeArea(item)">
                  <div class="all-area" :class="[activeArr.includes(item.id)? 'area-active' : '']">
                    {{item.name}}
                  </div>
                </div>
              </div>
              <div class="row area-box2">
                <div class="area-md5" v-for="(item,i) in md5" :key="i" @click="activeArea(item)">
                  <div class="area2 " :style="{backgroundColor:(activeArr.includes(item.id)?'#ff7b00':'#fff'),color:(activeArr.includes(item.id)?'#fff':'#ff7b00')}">
                    {{item.name}}
                  </div>
                </div>
                <div class="area-md7" v-show="active===1" v-for="(item,i) in md7" :key="i" @click="activeArea(item)">
                  <div class="area3" :style="{backgroundColor:(activeArr.includes(item.id)?'#ff7b00':'#fff'),color:(activeArr.includes(item.id)?'#fff':'#ff7b00')}">
                    {{item.name}}</div>
                </div>
              </div>
            </div>
          </div>
          <img class="lazy train-footer-img1" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-footer.png" alt="">
          <img class="lazy train-footer-img2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-footer.png" alt="">
          <img class="lazy train-footer-img3" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-2.png" alt="">

        </div>
        <!-- 地区模块 -->
        <div class="facilities-tracks2">
          <img class="lazy tracks-2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-2.png" alt="">
          <img class="lazy tracks-3" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-2.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(6))?'none':'block')}">
          <img class="lazy tracks-6" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-6.png" alt="" v-show="active===1" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}">
        </div>
        <div class="col-md-12 " style="display:flex;flex-direction: column;">
          <div class="facilities-detail">
            <div class="app-container">
              <img class="lazy point-3" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-3.png" alt="">
              <img class="tracks-2" :style="{display:(activeArr.includes(4)?'none':'block')}" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-2.png" alt="">
              <img class="lazy point-4" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-4.png" alt="" :style="{display:(activeArr.includes(4)?'none':'block')}">
              <img class="lazy point-5" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-5.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}">
              <img class="lazy point-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/cart.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}">
              <img class="tracks-6" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-6.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}">
              <img class="lazy tracks-point-2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-2.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}">
              <img class="tracks-2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-2.png" alt="" :style="{display:(activeArr.includes(2)?'none':'block')}">
              <img class="lazy tracks-fox" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-fox.png" alt="">
              <img class="tracks-3" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-2.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(6))?'none':'block')}">
              <img class="lazy tracks-point-3" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-3.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}">
              <img class="lazy train-yellow" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-yellow.png" alt="" :style="{display:((activeArr.includes(1))?'block':'none')}">
              <img class="lazy tracks-door" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/door.png" alt="" :style="{display:((activeArr.includes(1))?'block':'none')}">
              <img class="tracks-middle" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-middle.png" alt="" :style="{display:((activeArr.includes(1))?'blobk':'none')}">
              <img class="tracks-middle2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-middle.png" alt="" :style="{display:((activeArr.includes(1))?'blobk':'none')}">
              <div class="detail-item-list col-md-12">
                <div class="col-md-4" v-for="(item,i) in showList" :key="i" @click="open(item)">
                  <div class="detail-item-box" v-if="active === 1">
                    <div class="detail-item" id="detail-item">
                      <img class="lazy detail-item-img" :src="item.img" :data-url="item.img">
                      <div class="detail-item-areaName">
                        <p v-for="(item ,index) in splitBatName(item.ticketName)" :key="index">{{item}}</p>
                      </div>
                    </div>
                    <span class="detail-item-productName">{{item.content}}</span>
                  </div>
                  <div class="detail-item-box" v-else v-show="active === 2 && item.activeId === 1">
                    <div class="detail-item" id="detail-item">
                      <img class="lazy detail-item-img" :src="item.img" :data-url="item.img">
                      <div class="detail-item-areaName">
                        <p v-for="(item ,index) in splitBatName(item.ticketName)" :key="index">{{item}}</p>
                      </div>
                    </div>
                    <span class="detail-item-productName">{{item.content}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class=" col-md-12 detail-item-attentionBox">
            <p class="detail-item-attention">주의사항</p>
            <p class="detail-item-attention2">*ALL PASS의 유효기간은 에리어 패스의 유효기간과 상관없이 티켓에 표시된 날짜에 따릅니다.</p>
            <p class="detail-item-attention2">*간사이공항 특급 하루카 편도티켓 및 에리어 패스는 반드시 ALL PASS 유효기간 종료일까지 교환해 주세요.<br>유효기간 종료일까지 교환하지 않을 경우, 이용하실 수 없습니다.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹出 -->
    <div class="backdrop" v-if='showDialog'>
      <div class="backdrop-content">
        <img class="lazy glyphicon" :src="close" alt="" @click="close2()">
        <div class="backdrop-swiper">
          <el-carousel class="banner" trigger="click" height="281px" :interval="3000">
            <el-carousel-item v-for="(item,index) in ticketDetials.tickeImg" :key="index">
              <img class="backdrop-content-img" :src="item" lazy />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="backdrop-content-box">
          <p class="travel-copyright" v-if="ticketDetials.copyright">{{ticketDetials.copyright}}</p>
          <p class="travel-facilityName" :style="{marginTop:ticketDetials.id===21?'0px':'16px'}">
            {{ticketDetials.areaNAme}}</p>
          <p class="content-name" v-for="(item ,index) in splitBatName(ticketDetials.ticketName2)" :key="index">{{item}}
          </p>
          <div class="backdrop-travel-cartBox">
            <img class="lazy backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/facility_area.png" alt="">
            <div class="" style="display: flex;flex-direction: column;">
              <span class="backdrop-travel-cartName"><a :href="ticketDetials.Http" target=_blank>{{ticketDetials.Http}}</a></span>
            </div>
            <div class="" style="display: flex;flex-direction: column;" v-if="ticketDetials.Http3">
              <span class="backdrop-travel-cartName"><a href="/public/gurumeguri_map2022.pdf" target=_blank>{{ticketDetials.Http4}}</a></span>
              <span class="backdrop-travel-cartName"><a href="/public/gurumeguri2022.pdf" target=_blank>{{ticketDetials.Http3}}</a></span>
            </div>
            <span class="backdrop-travel-cartName" style="margin-left: 0;color: #000;">{{ticketDetials.Http2}}</span>
          </div>
          <div class="backdrop-travel-cartBox">
            <img class="lazy backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-facilities.png" alt="">
            <div>
              <p class="backdrop-travel-cartName" v-for="(item ,index) in splitBatName(ticketDetials.Faci)" :key="index">{{item}}</p>
            </div>
          </div>
          <div class="backdrop-travel-cartBox">
            <img class="lazy backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-cart.png" alt="">
            <div>
              <p class="backdrop-travel-cartName" v-for="(item ,index) in splitBatName(ticketDetials.Doer)" :key="index">{{item}}</p>
            </div>
          </div>
          <div class="backdrop-travel-cartBox">
            <img class="lazy backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-name.png" alt="">
            <div>
              <p class="backdrop-travel-cartName" v-for="(item ,index) in splitBatName(ticketDetials.time)" :key="index">{{item}}</p>
            </div>
          </div>
          <div class="backdrop-travel-cartBox">
            <img class="lazy backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-calendar.png" alt="">
            <div>
              <p class="backdrop-travel-cartName" v-for="(item ,index) in splitBatName(ticketDetials.Calendar)" :key="index">{{item}}
              </p>
            </div>
          </div>
          <p class="backdrop-travel-cartBox">
            <img class="lazy backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-price.png" alt="">
            <span class="backdrop-travel-cartName">￥{{ticketDetials.price}}</span>
          </p>
          <div class="backdrop-travel-cartBox" v-if="ticketDetials.content2">
            <div>
              <p class="backdrop-travel-cartName" v-for="(item ,index) in splitBatName(ticketDetials.content2)" :key="index">{{item}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      PC_home: require("@/assets/images/allpass/PC-home.png"),
      PC_line: require("@/assets/images/allpass/PC_line.png"),
      ticket_zhu2: require("@/assets/images/allpass/ticket_ko1.png"),
      ticket_zhu: require("@/assets/images/allpass/ticket_ko2.png"),
      close: require("@/assets/images/allpass/close.png"),
      rid_copy: require("@/assets/images/allpass/copyright.png"),
      loadImg: require("@/assets/images/allpass/loading.png"),
      active: 1,
      animateTime: 3000, // 要和 css 的过渡一致
      activeAreaName: 1,
      ticketDescribe: "관광시설 공통권 (29시설)",
      planName: ["간사이 와이드 에리어 패스", "5 Days 세트 플랜"],
      planPrice: "27,700",
      planName2: ["간사이 히로시마 에리어 패스", "5 Days 세트 플랜"],
      planPrice2: "32,000",
      planName3: "JR서일본 전지역 패스",
      planPrice3: "39,000",
      ticketDetials: {},
      showDialog: false,
      drawer: false,
      innerDrawer: false,
      startX: "",
      startY: "",
      moveY: "",
      moveX: "",
      startTime: "",
      activeArr: [1],
      activeAreaArr: [],
      ticketArr: [
        {
          id: 1,
          type: 1,
          fontcolor: "#F98304",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-12.png",
          ticketName: "서일본 탐방권",
          validityDay: 4,
          price: "21,000~",
          describe: [
            "JR 승차권 + ",
            "유니버설 스튜디오 재팬 1 DAY 스튜디오 패스 + ",
            "관광시설 공통권 (29시설)",
          ],
        },
        {
          id: 2,
          type: 2,
          fontcolor: "#F4B22D",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-22.png",
          ticketName: "간사이 탐방권",
          validityDay: 3,
          price: "16,000~",
          describe: [
            "JR 승차권 + ",
            "유니버설 스튜디오 재팬 1 DAY 스튜디오 패스 + ",
            "관광시설 공통권 (16시설)",
          ],
        },
      ],
      bannerImg: [
        require("@/assets/images/allpass/pc_banner.png"),
        require("@/assets/images/allpass/PC_CH.png"),
      ],
      bannerMb: [
        "https://qbcdn.jtrchina.com/tripelletexploreallpass/banner/mb_banner.png",
        "../img/MB_CH.png",
      ],
      /*
                西日本数组
                areaType 1京都 2大阪 3滋賀・兵庫・和歌山 4山陽山陰 5石川
                */
      scenicSpotArr: [
        // 京都
        {
          id: 1,
          activeId: 1,
          areaNAme: "교토",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_A.jpg",
          ticketName: ["교토 철도 박물관 입장권 "],
          ticketName3: ["교토 철도 박물관 입장권 "],
          ticketName4: ["교토 철도 박물관 입장권 "],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_D.jpg",
          ],
          Doer: ["우메코지 교토니시역에서 도보 2분"],
          time: ["10:00 ～ 17:00 (최종입장 16:30)"],
          Http: "http://www.kyotorailwaymuseum.jp/kr/",
          Faci: ["京都府京都市下京区観喜寺町", "교토 철도 박물관 티켓판매소"],
          Calendar: ["매주 수요일, 연말연시 (임시휴일 있음)"],
          areaType: 1,
          price: "1,200",
        },
        {
          id: 2,
          activeId: 1,
          areaNAme: "교토",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_A.jpg",
          ticketName: ["도에이 우즈마사 영화촌 입장권"],
          ticketName3: ["도에이 우즈마사 영화촌 입장권"],
          ticketName4: ["도에이 우즈마사 영화촌 입장권"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_D.jpg",
          ],
          Doer: ["JR 하나조노역에서 도보 약13분"],
          time: [
            "10:00 ～ 17:00",
            "※ 임시휴업이 있을 수 있으므로, 공식사이트에서 영업시간을 확인해 주세요",
          ],
          Http: "http://www.toei-eigamura.com/global/ko/",
          Faci: [
            "日本京都市右京区太秦東峰岡町10",
            "도에이 우즈마사 영화촌 티켓판매소",
          ],
          Calendar: ["홈페이지에서 확인"],
          areaType: 1,
          price: "2,400",
        },
        {
          id: 3,
          activeId: 1,
          areaNAme: "교토",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_A.jpg",
          ticketName: ["아라시야마 미식투어 쿠폰", "(8점포 중 3곳 선택)"],
          ticketName3: ["아라시야마 미식투어 쿠폰", "(8점포 중 3곳 선택)"],
          ticketName4: ["아라시야마 미식투어 쿠폰", "(8점포 중 3곳 선택)"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_D.jpg",
          ],
          Doer: ["JR 사가아라시야마역에서 도보 8분"],
          time: ['란덴 "아라시야마역 안내소" 09:00 ～ 18:00'],
          Http4: "https://www.tripellet.com/gurumeguri_map2022.pdf",
          Http3: "https://www.tripellet.com/gurumeguri2022.pdf",
          Faci: ["京都府京都市右京区嵯峨天龍寺", "造路町20－2（嵐電嵐山駅）"],
          Calendar: ["※ 각 매장의 영업시간은 실물", "쿠폰에서 확인해 주세요."],
          areaType: 1,
          price: "1,000",
        },
        {
          id: 4,
          activeId: 1,
          areaNAme: "교토",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_A.jpg",
          ticketName: ["Sky Hop Bus", "교토순환버스"],
          ticketName3: ["Sky Hop Bus", "교토순환버스"],
          ticketName4: ["Sky Hop Bus", "교토순환버스"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_C.jpg",
          ],
          Doer: ["JR 교토역 (가라스마 출구) 도보 1분"],
          time: ["웹사이트를 참고해 주십시오(임시휴일 있음)"],
          Http: "https://skyhopbus.com/kyoto/",
          Faci: ["京都市下京区烏丸通塩小路下ル東塩小路町", "교토역 티켓판매소"],
          Calendar: ["홈페이지에서 확인"],
          areaType: 1,
          price: "2,500",
        },
        {
          id: 5,
          activeId: 1,
          areaNAme: "교토",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_A.jpg",
          ticketName: ["유메야카타 기모노렌탈샵 고조점"],
          ticketName3: ["유메야카타 기모노렌탈샵 고조점"],
          ticketName4: ["유메야카타 기모노렌탈샵 고조점"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_D.jpg",
          ],
          Doer: ["JR 지하철 카라스마선 고조역에서 도보3분"],
          time: ["10:00  ～ 17:30（ 최종입장 16:00）"],
          Http: "https://www.kr-kyoto.yumeyakata.com/",
          Faci: ["京都市下京区塩竈町353"],
          Calendar: ["연말연시(12/31 ～ 1/3)"],
          areaType: 1,
          price: "3,850",
        },

        {
          id: 6,
          areaNAme: "교토",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_A.jpg",
          ticketName: ["아마노하시다테", " 이네 2 DAY 프리티켓"],
          ticketName3: ["아마노하시다테", " 이네 2 DAY 프리티켓"],
          ticketName4: ["아마노하시다테", " 이네 2 DAY 프리티켓"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_D.jpg",
          ],
          Doer: ["교토 단고 철도 아마노하시다테역 내"],
          time: ["교환시간 10:00 ～ 16:30", "(아마노하시다테역 관광안내소)"],
          Http: "https://www.tankai.jp/tourist_tickets/",
          Faci: [
            "京都府宫津市字文珠314-2",
            '아마노하시다테역 안내소 "아마노하시다테 관광협회"',
          ],
          Calendar: ["연중 무휴"],
          areaType: 1,
          price: "2,600",
        },
        // 大阪
        {
          id: 7,
          activeId: 1,
          areaNAme: "오사카",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_A.jpg",
          ticketName: ["우메다 스카이빌딩", "공중정원 전망대 입장권"],
          ticketName3: ["우메다 스카이빌딩", "공중정원 전망대 입장권"],
          ticketName4: ["우메다 스카이빌딩", "공중정원 전망대 입장권"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_D.jpg",
          ],
          Doer: ["JR 오사카역에서 도보 7분"],
          time: ["9:30 ～ 22:30（최종입장:22:00）"],
          Http: "https://www.skybldg.co.jp/ko/",
          Faci: ["大阪市北区大淀中1-1-88", "(우메다 스카이빌딩 39층 프론트)"],
          Calendar: ["연중 무휴"],
          areaType: 2,
          price: "1,500",
        },
        {
          id: 8,
          activeId: 1,
          areaNAme: "오사카",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_A.jpg",
          ticketName: ["아베노 하루카스 "],
          ticketName3: ["아베노 하루카스 "],
          ticketName4: ["아베노 하루카스 "],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_D.jpg",
          ],
          Doer: ["JR 덴노지역 근처"],
          time: ["9:00 ～ 22:00  (최종입장:19:30)"],
          Http: "https://www.abenoharukas-300.jp/index.html",
          Faci: ["大阪市阿倍野区阿倍野筋1-1-43", "(16층 안내소)"],
          Calendar: ["연중 무휴"],
          areaType: 2,
          price: "1,500",
        },
        {
          id: 9,
          activeId: 1,
          areaNAme: "오사카",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_A1.jpg",
          ticketName: ["오사카 그란비아 호텔", '"티 라운지"디저트 세트'],
          ticketName3: ['오사카 그란비아 호텔 "티 라운지"디저트 세트 '],
          ticketName4: ["오사카 그란비아 호텔", '"티 라운지"디저트 세트 '],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_A1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_B1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_C1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_D1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_E1.jpg",
          ],
          Doer: ["JR 오사카역 중앙출구 직결"],
          time: [
            "디저트세트 제공시간 : 11:00-16:00",
            "레스토랑 영업시간",
            "평일 10:00-20:00 (L.O. 19:30)",
            "토요일 09:30-20:00 (L.O. 19:30)",
            "일요일 /공휴일 09:30-18:00 (L.O. 17:30)",
          ],
          Http: "https://www.hotelgranviaosaka.jp/ko/dining/lobby-lounge/",
          Faci: ["大阪市北区梅田3丁目1-1", '그란비아 호텔 1층"티 라운지"'],
          Calendar: ["연중 무휴"],
          areaType: 2,
          price: "2,300",
          content: "",
          content2: [
            "※사진은 이미지입니다.시즌에 따라",
            "※식사내용이 변경될 수 있습니다.",
          ],
        },
        {
          id: 10,
          activeId: 1,
          areaNAme: "오사카",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_A.jpg",
          ticketName: ["오사카 수상버스"],
          ticketName3: ["오사카 수상버스"],
          ticketName4: ["오사카 수상버스"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_D.jpg",
          ],
          Doer: ['JR 칸조선 "오사카조코엔역" 에서 도보 3분'],
          time: [
            "출항시간 11:00 / 11:45 / 12:30",
            "13:15 / 14:00 / 14:45 / 15:30 / 16:15",
          ],
          Http: "http://suijo-bus.osaka",
          Faci: [
            "大阪市中央区大阪城2番地先",
            "(수상버스 선착장 오사카성항 승선권 판매소)",
          ],
          Calendar: [
            "평일 주2회, 7/25 오후 배편, 1월중순",
            "2월상순에 운휴일있습니다.",
          ],
          areaType: 2,
          price: "1,600",
          content: "",
          content2: ["봄 시즌(3월하순~4월상순)은 특별요금이 적용됩니다."],
        },
        {
          id: 11,
          activeId: 1,
          areaNAme: "오사카",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_A.jpg",
          ticketName: ["오사카 주택 박물관", "입장권"],
          ticketName3: ["오사카 주택 박물관 입장권"],
          ticketName4: ["오사카 주택 박물관 입장권"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_C.jpg",
          ],
          Doer: ["JR 덴마역에서 하차 후 도보로 약 7분"],
          time: ["10:00 ～ 17:00 (최종입장 16:30)"],
          Http: "http://konjyakukan.com/index.html",
          Faci: [
            "大阪市北区天神橋6丁目4-20",
            "(주거정보센터빌딩 8층 오사카 주택박물관)",
          ],
          Calendar: ["화요일, 연말연시 （ 12/28 ～ 1/4 ）"],
          areaType: 2,
          price: "400",
        },
        // 滋賀・兵庫・和歌山
        {
          id: 12,
          activeId: 1,
          areaNAme: "효고",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_A1.jpg",
          ticketName: ["고베 누노비키 허브 가든 ", "로프웨이 입장권 "],
          ticketName3: ["고베 누노비키 허브 가든 &", "로프웨이 입장권 "],
          ticketName4: ["고베 누노비키 허브 가든 &", "로프웨이 입장권 "],
          Doer: ["JR 신코베역에서 도보 5분"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_A1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_B1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_C1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_D1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_E1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_F1.jpg",
          ],
          time: ["(산로쿠역 4F 안내소) 9:30 ～ 16:45"],
          Http: "https://www.kobeherb.com/kr/",
          Faci: ["神户市中央区北野町1-4-3", "(산로쿠역 4층 프론트)"],
          Calendar: ["공식사이트에서 확인해 주세요."],
          areaType: 3,
          price: "1,800",
        },
        {
          id: 13,
          activeId: 1,
          areaNAme: "효고",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_A.jpg",
          ticketName: ["아카시 주변 스시집", "할인쿠폰'기예의 일품'"],
          ticketName3: ["아카시 주변 스시집 할인쿠폰 '기예의 일품'"],
          ticketName4: ["아카시 주변 스시집 할인쿠폰 '기예의 일품'"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_D.jpg",
          ],
          Doer: ["JR 아카시역"],
          time: ["9:00 ～ 19:00(평일)", "9:00 ～ 18:00(주말/공휴일)"],
          Http: "https://www.yokosoakashi.jp/sites/default/files/images/access/img/akashi_meguri_han.pdf",
          Faci: [
            "兵庫県明石市大明石町1-1-23",
            "(JR아카시역 구내 아카시안내소)",
          ],
          Calendar: ["연말연시（12/31 ～ 1/1 ）"],
          areaType: 3,
          price: "2,000",
        },
        {
          id: 14,
          activeId: 1,
          areaNAme: "효고",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_A.jpg",
          ticketName: ["효고현 아카시", "시립 천문과학관 입장권"],
          ticketName3: ["효고현 아카시 시립 천문과학관 입장권"],
          ticketName4: ["효고현 아카시 시립", "천문과학관 입장권"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_D.jpg",
          ],
          Doer: [
            "JR 아카시역에서 도보 약15분,",
            "산요전철 히토마루마에역에서 도보 약3분",
          ],
          time: [
            "아카시 안내소 :",
            "(평일) 09:00 ～ 19:00",
            "(주말/공휴일) 09:00 ～ 18:00 ",
            "아카시 시립 천문과학관:",
            "09:30 ～ 17:00(최종입장 16:30)",
          ],
          Http: "https://www.am12.jp/",
          Faci: [
            "교환장소 ：兵库县明石市",
            "大明石町1-1-23 piole明石西館",
            "시설주소 ：兵庫県明石市人丸町2-6",
          ],
          Calendar: ["연말연시, ※임시휴관있음"],
          areaType: 3,
          price: "700",
        },
        {
          id: 15,
          areaNAme: "효고",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
          ticketName: ["JENOVA호 아와지섬"],
          ticketName3: ["JENOVA호 아와지섬"],
          ticketName4: ["JENOVA호 아와지섬"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_C.jpg",
          ],
          Doer: ["JR 아카시역에서 도보 약 8분"],
          time: [
            "(평일)",
            "아카시 승선시간 ： 9:00 ～ 17:00",
            "이와야 승선시간 ： 9:00 ～ 17:00",
            "(주말/공휴일)",
            "아카시 승선시간 ： 9:00 ～ 17:00",
            "이와야 승선시간 ： 9:00 ～ 17:00",
            "※자세한 시간표는 공식사이트에서 확인해 주세요.",
          ],
          Http: "http://www.jenova-line.co.jp/jikoku.php",
          Faci: [
            "아카시 승선장 ：兵庫県明石市本町2-10-1",
            "이와야 승선장 ：兵庫県淡路市岩屋925-27",
            "※원하시는 승선장에서 ZENOVA를 이용할 수 있습니다.",
          ],
          Calendar: ["기상 조건이 나쁠 경우 결항될 수있습니다 "],
          areaType: 3,
          price: "530",
        },
        {
          id: 16,
          activeId: 1,
          areaNAme: "시가",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_A.jpg",
          ticketName: ["나가하마 낭만 패스포트", "(시내 관광시설 입장권) "],
          ticketName3: ["나가하마 낭만 패스포트", "(시내 관광시설 입장권) "],
          ticketName4: ["나가하마 낭만 패스포트", "(시내 관광시설 입장권) "],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_D.jpg",
          ],
          Doer: ["JR 나가하마역 내"],
          time: ["나가하마역 관 광안내소：9:15 ～ 16:45"],
          Http: "https://kitabiwako.jp/spot/spot_8966",
          Faci: ["長浜市北船町1番5號", "(나가하마역 관광안내소)"],
          Calendar: ["연말연시"],
          areaType: 3,
          price: "1,200",
        },
        {
          id: 17,
          activeId: 1,
          areaNAme: "시가",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_A.jpg",
          ticketName: [
            "레이크 비와 오쓰 프린스호텔 37층 레이크뷰 식당",
            "'Biona' 런치뷔페",
          ],
          ticketName3: [
            "레이크 비와 오쓰 프린스호텔",
            "37층 레이크뷰 식당 'Biona' 런치뷔페",
          ],
          ticketName4: [
            "레이크 비와 오쓰 프린스호텔 37층 레이크뷰 식당",
            "'Biona' 런치뷔페",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_E.jpg",
          ],
          Doer: ["JR 오쓰역(大津駅)에서 무료 셔틀버스로 약10분"],
          time: [
            "영업시간： 11:30 ～ 15:00",
            "식사시간： 11:30 ～ 13:00 / 13:30 ～ 15:00",
            "（시간제한 90분, 두가지 시간대）",
          ],
          Http: "https://www.princehotels.co.jp/otsu/restaurant/biona/",
          Faci: [
            "滋賀県大津市におの浜4-7-7",
            "びわ湖大津プリンスホテル",
            "(37층 레이크 뷰 식당‘Biona’)",
          ],
          Calendar: ["홈페이지에서 확인"],
          areaType: 3,
          price: "3,000",
        },
        {
          id: 18,
          activeId: 1,
          areaNAme: "시가",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_A.jpg",
          ticketName: ["비와호 치쿠부섬 크루즈", "※히코네 항~치쿠부시마(왕복)"],
          ticketName3: [
            "비와호 치쿠부섬 크루즈",
            "※히코네 항~치쿠부시마(왕복)",
          ],
          ticketName4: [
            "비와호 치쿠부섬 크루즈",
            "※히코네 항~치쿠부시마",
            "(왕복)",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_D.jpg",
          ],
          Doer: [
            "JR히코네역에서 무료 셔틀버스를 타고 약 8분",
            '"히코네코" 정류장에서 하차',
          ],
          time: [
            "3월 1일 ～ 12월 첫째주까지는 매일, 그 다음 날부터 2 월 말까지는 주말과 공휴일만",
          ],
          Http: "http://www.ohmitetudo.co.jp/marine/time-table_hikone-chikubushima-hikone_normal/index.html/",
          Faci: ["滋賀県彦根市松原町3755", "(히코네항 탑승장)"],
          Calendar: ["주말/공휴일 운항", "(평일, 12/30, 12/31은 운휴)"],
          areaType: 3,
          price: "3,000",
        },
        {
          id: 19,
          areaNAme: "시가",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_A.jpg",
          ticketName: ["하치만야마 로프웨이"],
          ticketName3: ["하치만야마 로프웨이"],
          ticketName4: ["하치만야마 로프웨이"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_D.jpg",
          ],
          Doer: [
            "JR오미하치만역 북쪽출구 6번승강장에서 초메지 방향으로 가는 버스를 타고 약7분, 오스키초 하치만야마 로프웨이구치 정거장에서 하차 ",
          ],
          time: ["09:00 ～ 17:00 （상행방향 마지막 운행 16:30）"],
          Http: "http://www.ohmitetudo.co.jp/hachimanyama/open-price/index.html/",
          Faci: [
            "滋賀県近江八幡市宮內町",
            "(하치만야마 로프웨이 산로쿠 티켓판매소)",
          ],
          Calendar: ["연중 무휴"],
          areaType: 3,
          price: "890",
        },
        {
          id: 20,
          areaNAme: "와카야마",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_A.jpg",
          ticketName: ["구시모토 마린파크 수족관 전망대 입장권"],
          ticketName3: ["구시모토 마린파크 수족관 전망대 입장권"],
          ticketName4: ["구시모토 마린파크 수족관 전망대 입장권"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_E.jpg",
          ],
          Doer: ["JR 구시모토역에서 무료 셔틀 버스를타고 12분"],
          time: ["9:00 ～ 16:30(매표소는 16:00까지)"],
          Http: "http://kushimoto.co.jp/english/",
          Faci: [
            "和歌山県東牟婁郡串本町有田1157",
            "(구시모토 마린 파크 수족관 티켓판매소)",
          ],
          Calendar: ["연중 무휴"],
          areaType: 3,
          price: "1,800",
        },
        {
          id: 21,
          areaNAme: "와카야마",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_A.jpg",
          ticketName: ["와카야마 기시가와선", "1일승차권"],
          ticketName3: ["와카야마 기시가와선 1일승차권"],
          ticketName4: ["와카야마 기시가와선 1일승차권"],
          copyright: "「DESIGNED BY EIJI MITOOKA+DON DESIGN ASSOCIATES」",
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_E.jpg",
          ],
          Doer: ["오시는 길: JR 와카야마역 9번 승강장"],
          time: [
            "차량종류에 따라 운행시각이 다릅니다.",
            "운휴일 및 고양이 역장 타마의 근무일, 근무시간은",
            "웹사이트에서 확인해 주세요.",
          ],
          Http: "http://www2.wakayama-dentetsu.co.jp/pdf/wakayama_korean.pdf",
          Faci: ["和歌山県和歌山市美园町5丁目2", "(와카야마역)"],
          Calendar: ["홈페이지에서 확인"],
          areaType: 3,
          price: "800",
        },
        // 山陽山陰
        {
          id: 22,
          areaNAme: "오카야마",
          img: require("@/assets/images/allpass/erdao_A.jpg"),
          ticketName: ["고지마 순환버스 승차권", "및 데님만들기 체험"],
          ticketName3: ["고지마 순환버스 승차권 및 데님만들기 체험"],
          ticketName4: ["고지마 순환버스 승차권 및 데님만들기 체험"],
          tickeImg: [
            require("@/assets/images/allpass/erdao_A.jpg"),
            require("@/assets/images/allpass/erdao_B.jpg"),
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/erdao_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/erdao_D.jpg",
          ],
          Doer: ["JR고지마역에서 버스로 약20분"],
          time: [
            "교환장소 : 09:00 ～ 17:00,",
            "베티스미스 : 09:00 ～ 18:00",
            "(체험시간 11:00 ～,14:00 ～)",
            "고지마 청바지버스 : ",
            "금, 토, 일, 공휴일후레아이 버스",
            "도코하이 버스(매일운행)",
          ],
          Http: "http://www.shimoden.net/rosen/kikaku/jeans.html",
          Faci: ["岡山県倉敷市児島駅前1丁目107", "(JR고지마역 개찰구)"],
          Calendar: [
            "청바지버스 (금, 주말, 공휴일 운행",
            "※연말연시제외)",
            "후레아이호/토코하이호 (매일운행)",
            "베티스미스 (연말연시휴업)",
          ],
          areaType: 4,
          price: "1,000",
        },
        {
          id: 23,
          areaNAme: "오카야마",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_A.jpg",
          ticketName: ["오카야마 그란비아 호텔「Lumiere」과일파르페"],
          ticketName3: ["오카야마 그란비아 호텔「Lumiere」과일파르페"],
          ticketName4: ["오카야마 그란비아 호텔「Lumiere」과일파르페"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_D.jpg",
          ],
          Doer: ["JR오카야마역 근방"],
          time: ["12:00 ～ 17:00(호텔 1층 로비)"],
          Http: "https://granvia-oka.co.jp/ko/restaurant/#lum",
          Faci: [
            "岡山県岡山市北区駅元町1-5",
            "ホテルグランヴィア岡山",
            "(호텔 1층「Lumiere」)",
          ],
          Calendar: ["연중 무휴"],
          areaType: 4,
          price: "2,500",
        },
        {
          id: 24,
          areaNAme: "히로시마",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_A.jpg",
          ticketName: ["굴 어장 소개 및", "이쓰쿠시마신사 해상유람"],
          ticketName3: ["굴 어장 소개 및 이쓰쿠시마신사 해상유람"],
          ticketName4: ["굴 어장 소개 및 이쓰쿠시마신사 해상유람"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_D.jpg",
          ],
          Doer: [
            "산요본선 JR미야지마역에서",
            "도보 약15분",
            "（ 크루즈 승선장에서 도보 약10분）",
          ],
          time: ["10:00 ～ 16:00"],
          Http: "http://shimadasuisan.com/taiken-en.html",
          Faci: ["広島県廿日市市宮島口西1-2-6", "(kaki티켓판매소)"],
          Calendar: ["연중 무휴"],
          areaType: 4,
          price: "2,200",
        },
        {
          id: 25,
          areaNAme: "히로시마",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
          ticketName: ["세토다 주유패스"],
          ticketName3: ["세토다 주유패스"],
          ticketName4: ["세토다 주유패스"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
          ],
          Doer: ["JR 오노미치역 내"],
          time: ["오노미치역 관광안내소", "9:00 ～ 18:00"],
          Http: "https://www.ononavi.jp/fan/onotabi/guruseto/",
          Faci: ["廣島縣尾道市東御所町1-1", "(오노미치역 관광안내소)"],
          Calendar: ["12/29 ～ 1/3"],
          areaType: 4,
          price: "4,500",
        },
        {
          id: 26,
          areaNAme: "야마구치",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_A.jpg",
          ticketName: ["야마구치현 싯쵸루ca"],
          ticketName2: ["(호후시내 관광시설", "입장권)"],
          ticketName3: ["야마구치현 싯쵸루ca", "(호후시내 관광시설 입장권)"],
          ticketName4: ["야마구치현 싯쵸루ca", "(호후시내 관광시설 입장권)"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_E.jpg",
          ],
          Doer: ["JR 호후역 중앙광장 내"],
          time: ["호후시 관광안내소：09:00 ～ 18:00"],
          Http: "https://visit-hofu.jp/en/",
          Faci: [
            "山口県防府市江八町1丁目1-1",
            "(JR호후역 구내 호후시 관광안내소)",
          ],
          Calendar: ["연중 무휴"],
          areaType: 4,
          price: "1,200",
        },
        {
          id: 27,
          areaNAme: "시마네",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_A.jpg",
          ticketName: ["일본정원 유시엔"],
          ticketName3: ["일본정원 유시엔"],
          ticketName4: ["일본정원 유시엔"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_E.jpg",
          ],
          Doer: ["JR마쓰에역 6번 승강장에서 버스로 약 50분"],
          time: ["10:00 ～ 17:00 (최종입장 16:30)"],
          Http: "https://www.yuushien.com/language/kr.html",
          Faci: ["松江市八束町波入1260-2", "(유시엔 티켓판매소)"],
          Calendar: ["12/30 ～ 31"],
          areaType: 4,
          price: "1,000",
        },
        // 石川
        {
          id: 28,
          areaNAme: "이시카와",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_A.jpg",
          ticketName: ["카가 전통공예촌", "유노쿠니노모리"],
          ticketName3: ["카가 전통공예촌", "유노쿠니노모리"],
          ticketName4: ["카가 전통공예촌", "유노쿠니노모리"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_D.jpg",
          ],
          Doer: ["JR가가온센역에서 CANBUS 야마마와리선으로 약 40분"],
          Http: "https://www.yunokuni.jp/mori/?lang=kr",
          Faci: ["石川県小松市栗津溫泉Na3-3", "(카가 전통공예촌 티켓판매소)"],
          Calendar: ["※영업일은 WEB사이트에서 확인해 주세요."],
          time: ["9:00 ～ 16:30（ 최종입장 15:30）"],
          areaType: 5,
          price: "550",
        },
        {
          id: 29,
          areaNAme: "이시카와",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_A.jpg",
          ticketName: ["카가 주유버스 CANBUS", "1일승차권"],
          ticketName3: ["카가 주유버스", "CANBUS 1일승차권"],
          ticketName4: ["카가 주유버스", "CANBUS 1일승차권"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_D.jpg",
          ],
          Doer: ["JR카가온센역 근처"],
          time: ["(교환) 마치즈쿠리 카가 주유권 판매소 08:30 ～ 18:00"],
          Http: "http://www.kaga-canbus.jp/english.php",
          Faci: ["石川県加賀市作見町RI 1-3", "(마치즈쿠리 카가 티켓판매소)"],
          Calendar: ["08:30 ～ 18:00, 정기휴일 : 연중무휴"],
          areaType: 5,
          price: "1,100",
        },
      ],
      showList: [],
      md3: [
        {
          id: 1,
          name: "모든 지역",
          num: 29,
          areaType: 0,
        },
        {
          id: 2,
          name: "오사카",
          areaType: 2,
        },
        {
          id: 3,
          name: "교토",
          areaType: 1,
        },
      ],
      md4: [
        {
          id: 4,
          name: "이시카와",
          areaType: 5,
        },
      ],
      md5: [
        {
          id: 5,
          name: "시가·효고·와카야마",
          areaType: 3,
        },
      ],
      md7: [
        {
          id: 6,
          name: "산인 산요",
          areaType: 4,
        },
      ],
    };
  },
  created() {
    this.showArea();
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")];
    },
    language() {
      return localStorage.getItem("locale") || "zh_tw";
    },
  },
  methods: {
    change(item) {
      this.active = item.id;
      if (this.active === 1) {
        this.ticketDescribe = "관광시설 공통권 (29시설)";
        this.planName = ["간사이 와이드 에리어 패스", "5 Days 세트 플랜"];
        this.planPrice = "27,700";
        this.planName2 = ["간사이 히로시마 에리어 패스", "5 Days 세트 플랜"];
        this.planPrice2 = "32,000";
        this.planName3 = "JR서일본 전지역 패스";
        this.planPrice3 = "39,000";
        this.md3[0].num = 29;
        this.showList = this.scenicSpotArr;
        let array = {
          id: 15,
          areaNAme: "효고",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
          ticketName: ["JENOVA호 아와지섬"],
          ticketName3: ["JENOVA호 아와지섬"],
          ticketName4: ["JENOVA호 아와지섬"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_C.jpg",
          ],
          Doer: ["JR 아카시역에서 도보 약 8분"],
          time: [
            "(평일)",
            "아카시 승선시간 ： 9:00 ～ 17:00",
            "이와야 승선시간 ： 9:00 ～ 17:00",
            "(주말/공휴일)",
            "아카시 승선시간 ： 9:00 ～ 17:00",
            "이와야 승선시간 ： 9:00 ～ 17:00",
            "※자세한 시간표는 공식사이트에서 확인해 주세요.",
          ],
          Http: "http://www.jenova-line.co.jp/jikoku.php",
          Faci: [
            "아카시 승선장 ：兵庫県明石市本町2-10-1",
            "이와야 승선장 ：兵庫県淡路市岩屋925-27",
            "※원하시는 승선장에서 ZENOVA를 이용할 수 있습니다.",
          ],
          Calendar: ["기상 조건이 나쁠 경우 결항될 수있습니다 "],
          areaType: 3,
          price: "530",
          content: "",
          content2: "",
        };
        this.showList.findIndex((item) => {
          if (item.id === 14) {
            return true;
          }
        });
        this.showList.splice(14, 0, array);
      } else {
        this.ticketDescribe = "관광시설 공통권 (16시설)";
        this.planName = ["간사이 에리어 패스 3 Days 세트 플랜"];
        this.planPrice = "19,300";
        this.planName2 = ["간사이 와이드패스 5일권"];
        this.planName3 = "";
        this.planPrice3 = "";
        this.md3[0].num = 16;
        this.showList = this.scenicSpotArr;
        let id15 = this.showList.findIndex((item) => {
          if (item.id === 15) {
            return true;
          }
        });
        this.showList.splice(id15, 1);
      }
      this.activeAreaArr = []; //清除已选中的地区id
      this.activeArr = [1]; //切换探游券时 默认全部
    },
    openSmall(item) {
      this.ticketDetials = item;
      this.innerDrawer = true;
    },
    open(item) {
      this.ticketDetials = item;
      this.showDialog = true;
      this.innerDrawer = true;
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); // 禁止页面底部内容滑动
    },
    close2() {
      this.showDialog = false;
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; // 出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
    showArea() {
      this.showList = this.scenicSpotArr;
    },
    activeArea(item) {
      let index = 0;
      this.showList = [];
      if (item.areaType === 0) {
        // 选择了所有
        this.activeArr = [1]; //所有
        this.activeAreaArr = [];
        this.active === 1
          ? (this.showList = this.scenicSpotArr)
          : (this.showList = this.scenicSpotArr); //判断是否西日本
        return;
      }
      if (item.areaType !== 0) {
        // 没有选择全部地区并且选的是西日本
        let alldelIndex = this.checkArray(this.activeArr, 1); //找到复选地区中所有地区的下标
        alldelIndex !== -1 ? this.activeArr.splice(alldelIndex, 1) : ""; //如果有所有地区的下标就删除
        let activeArrDelIndex = this.checkArray(this.activeArr, item.id); //查找复选地区是否已经存在
        let activeAreaArrDelIndex = this.checkArray(
          this.activeAreaArr,
          item.areaType
        ); //查找复选地区是否已经存在
        activeArrDelIndex === -1
          ? this.activeArr.push(item.id)
          : this.activeArr.splice(activeArrDelIndex, 1); //如果目前地区存在就删除，不然就加入
        activeAreaArrDelIndex === -1
          ? this.activeAreaArr.push(item.areaType)
          : this.activeAreaArr.splice(activeAreaArrDelIndex, 1); //查找复选地区是否已经存在
        this.scenicSpotArr.forEach((item1) => {
          if (item.areaType === item1.areaType) {
            // 去重
            let newArr = [];
            for (let i = 0; i < this.activeArr.length; i++) {
              newArr.indexOf(this.activeArr[i]) == -1
                ? newArr.push(this.activeArr[i])
                : "";
            }
            this.activeArr = newArr;
          }
        });
        this.activeAreaArr = this.unique(this.activeAreaArr); //景点属性去重
        this.scenicSpotArr.forEach((item11) => {
          this.activeAreaArr.forEach((item12) => {
            item12 == item11.areaType ? this.showList.push(item11) : "";
            item12 == item11.areaType ? console.log(item11) : "";
          });
        });
      } else {
        this.activeArr = [1];
        this.showList = this.scenicSpotArr;
      }
    },
    unique(arr) {
      var result = [];
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] === arr[j]) {
            j = ++i;
          }
        }
        result.push(arr[i]);
      }
      return result;
    },
    checkArray(arr, target) {
      return arr.indexOf(target);
    },
    handleClose(done) {
      done();
    },
    splitBatName(str) {
      return str;
    },
  },
};
</script>

<style>
@import "~@/assets/css/zh.scss";
.train-footer-img1,
.train-footer-img2 {
  bottom: -249px;
}
</style>