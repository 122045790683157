<template>
  <div @load="loading()">
    <div class="touch" @touchstart='touchstart' @touchmove='touchmove'>
      <topMb></topMb>
      <!-- 轮播 -->
      <el-carousel :height="banH">
        <el-carousel-item v-for="item in bannerMb" :key="item" class="carousel-item">
          <img ref="imgH" class="carousel-img" :src="item" @load="setbanH()" />
        </el-carousel-item>
      </el-carousel>
      <!-- 切换 -->
      <div class="main-travel ">
        <div class="train-box">
          <div class="app-container">
            <div class="col-md-12 travel-item-box">
              <el-image src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-1.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
              <div class="travel-item-md6" v-for="(item,i) in ticketArr" :key="i" @click="change(item)">
                <div :class="[active===item.id ? 'travel-item1' : 'travel-item']">
                  <el-image class="travel-item-img" :src="item.img" lazy></el-image>
                  <p class="travel-right">유효일
                    <span class="travel-right-day">
                      <span :style="{'color':item.fontcolor}">{{item.validityDay}}</span>&nbsp;일</span>
                  </p>
                  <p class="travel-detail" v-for="(item ,index) in splitBatName(item.describe)" :key="index">{{item}}</p>
                  <p class="travel-price" :style="{'color':item.fontcolor}">￥{{item.price}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 套餐内容 -->
      <div class="main-package">
        <div class="app-container">
          <div class="col-md-12  package-content-box">
            <div class="package-content flex-arround">
              <el-image class="package-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
              <p class="package-title">ALL PASS 패키지 콘텐츠</p>
              <el-image class="package-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
            </div>
          </div>
          <div class="col-md-12 flex-justify-c">
            <div class="package-riding-content2">
              <div class="riding-box">
                <div class="riding-right">
                  <p class="riding-right-title">JR 승차권</p>
                  <p class="right-position">지정석 이용 가능</p>
                  <p class="riding-right-title2">간사이 공항 특급 하루카 편도 티켓</p>
                  <p class="riding-right-title22">(간사이 공항역 → 교토역)</p>
                  <div class="riding-line"></div>
                  <p class="riding-right-title3">간사이 공항 특급 하루카 편도 티켓을 JR WEST RAIL TICKET 으로도 변경할 수 있습니</p>
                  <el-image class="riding-right-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/riding-2.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <el-image class="riding-copyright" :src="copyright" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" style="width: 309px;height:10px;margin-left: -12px;" alt="" />
                    </div>
                  </el-image>
                  <div class="col-md-12 five_days_box">
                    <div class="five_days_title" :class="[active===2 ? 'riding-bottom-1-1' : '']">
                      <div>
                        <p class="riding-bottom-t" v-for="(item ,index) in splitBatName(planName)" :key="index">{{item}}</p>
                      </div>
                      <p class="riding-bottom-p">￥{{planPrice}}</p>
                    </div>
                  </div>
                  <div class="col-md-12 five_days_box" v-if="planName2 && planPrice2" :class="[active===2 ? 'riding-bottom-2-1' : '']">
                    <div class="five_days_title">
                      <div>
                        <p class="riding-bottom-t" v-for="(item ,index) in splitBatName(planName2)" :key="index">{{item}}</p>
                      </div>
                      <p class="riding-bottom-p">￥{{planPrice2}}</p>
                    </div>
                  </div>
                  <div class="col-md-12 five_days_box" v-if="planName3 && planPrice3">
                    <div class="five_days_title">
                      <p class="riding-bottom-t" style="margin-bottom: 7px;">{{planName3}}</p>
                      <p class="riding-bottom-p">￥{{planPrice3}}</p>
                    </div>
                  </div>
                  <div class="col-md-12 five_days_box">
                    <div class="five_days_attention">
                      <p>※1회 한정 유효</p>
                      <p>※덴노지 신오사카역에서도 하차 가능</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!-- 日本环球影城 -->
          <div class="package-universal-content2 ">
            <div class="universal-left">
              <p class="universal-left-1">유니버설 스튜디오 재팬<br>1 DAY 스튜디오 패스</p>
              <el-image class="lazy universal-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/universal-zh1.jpg" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
              <p class="universal-left-3">
                <span>어른들도 확실하게 만족할 수 있는 세계 최고 퀄리티의 엔터테인먼트가 한가득!<br>테마파크 업계 최고 영예'Thea상'을 수상한 세계 최초*의 마리오 에어리어와<br>'마리오 카트:
                  쿠파의 도전장™'은 물론,'더 플라잉 다이너소어'를 필두로 한<br>엄청난 자극의 어트랙션,위저딩 월드 오브 위저딩 월드<br>오브 해리 포터™와 미니언 등<br>인기 절정 브랜드
                  어트랙션등,
                  최고의 감동과 흥분을 선사하는 파크에서 힐링해 보세요!</span>
              </p>
              <div class="universal-left-4">
                <p>*닌텐도 브랜드의 캐릭터 및 세계관을 테마로 한 시설을 대상으로 합동회사 유에스제이 조사</p>
              </div>
            </div>
          </div>
          <!-- 观光设施共通劵 -->
          <div class="facilities-content2 ">
            <div class="facilities-box flex-arround">
              <el-image class="lazy facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
              <p class="facilities-title">관광시설 공통권<br><span>{{md3[0].num}}시설</span></p>
              <el-image class="lazy facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
            </div>
            <div class="facilities-attention2 ">
              <p>1. 임시휴업인 경우가 있습니다. 이용당일 반드시 해당 시설의 최신정보 및 영업시간을 확인해 주세요.</p>
              <p>2. 한 시설에 입장한 후(QR코드 스캔 후) 다음 시설을이용하기 위해서는 2시간이 경과한 후부터 가능합니다.</p>
              <p>3. 같은 시설을 두 번 입장하실 수 없습니다.</p>
            </div>
            <div class="area-box">
              <div class="area-Box">
                <div class="col-md-12">
                  <div class="all-area" :style="{backgroundColor:(activeArr.includes(1)?'#ff7b00':'#fff'),color:(activeArr.includes(1)?'#fff':'#ff7b00')}" @click="activeArea({id:1,areaType:0})">모든 지역
                  </div>
                </div>
                <div class="col-md-12" style="display: flex;justify-content: space-between;">
                  <div class="area" :style="{backgroundColor:(activeArr.includes(2)?'#ff7b00':'#fff'),color:(activeArr.includes(2)?'#fff':'#ff7b00')}" @click="activeArea({id:2,areaType:2})">오사카</div>
                  <div class="area" :style="{backgroundColor:(activeArr.includes(3)?'#ff7b00':'#fff'),color:(activeArr.includes(3)?'#fff':'#ff7b00')}" @click="activeArea({id:3,areaType:1})">교토</div>
                </div>
                <div class="col-md-12">
                  <div class="area2" :style="{backgroundColor:(activeArr.includes(5)?'#ff7b00':'#fff'),color:(activeArr.includes(5)?'#fff':'#ff7b00')}" @click="activeArea({id:5,areaType:3})">시가·효고·와카야마</div>
                  <div class="col-md-12" style="display: flex;justify-content: space-between;">
                    <div class="area-md3" v-show="this.active===1" :style="{backgroundColor:(activeArr.includes(4)?'#ff7b00':'#fff'),color:(activeArr.includes(4)?'#fff':'#ff7b00')}" @click="activeArea({id:4,areaType:5})">이시카와</div>
                    <div class="area3" v-show="this.active===1" :style="{backgroundColor:(activeArr.includes(6)?'#ff7b00':'#fff'),color:(activeArr.includes(6)?'#fff':'#ff7b00')}" @click="activeArea({id:6,areaType:4})">산인 산요</div>
                  </div>
                </div>
                <div class="col-md-12 search-bg" style="display: flex; justify-content: center;">
                  <div class="area-search" @click="drawer = true">검색</div>
                </div>
              </div>
              <div class="area-small-attention">
                <p>주의사항</p>
                <p>*ALL PASS의 유효기간은 에리어 패스의 유효기간과 상관없이 티켓에 표시된 날짜에 따릅니다.</p>
                <p>*간사이공항 특급 하루카 편도티켓 및 에리어 패스는 반드시 ALL PASS 유효기간 종료일까지 교환해 주세요. 유효기간 종료일까지 교환하지 않을 경우, 이용하실 수 없습니다.</p>
              </div>
              <el-image class="area-small-bg" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/small_bg.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 抽屉 -->
    <div class="touch" @touchstart='touchstart' @touchmove='touchmove1' style="touch-action: pan-y!important;">
      <el-drawer title="" :visible.sync="drawer" :with-header="true" :destroy-on-close="true">
        <!-- 观光设施 抽屉-->
        <div class="main-facilities">
          <div class="facilities-box2">
            <el-image class="facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" lazy>
              <div slot="placeholder" class="image-slot">
                <img :src="loadImg" class="loadImg" alt="" />
              </div>
            </el-image>
            <p class="facilities-title">관광시설</p>
            <el-image class="facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" lazy>
              <div slot="placeholder" class="image-slot">
                <img :src="loadImg" class="loadImg" alt="" />
              </div>
            </el-image>
          </div>
          <div class="col-md-12">
            <div class="area-title">
              <div class="area-title1">
                <div class="area-title-item">
                  <span :style="{backgroundColor:((activeArr.includes(2) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(2) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(2) || activeArr.includes(1))?'10px':'0')}" @click="activeArea({id:2,areaType:2})">오사카</span>
                </div>
                <div class="area-title-item" :style="{borderRight:(this.active===1?'1px solid #ff7b00':'0')}">
                  <span :style="{backgroundColor:((activeArr.includes(3) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(3) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(3) || activeArr.includes(1))?'10px':'0')}" @click="activeArea({id:3,areaType:1})">교토</span>
                </div>
              </div>
              <div class="area-title1">
                <div class="area-title-item" v-show="this.active===1">
                  <span :style="{backgroundColor:((activeArr.includes(4) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(4) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(4) || activeArr.includes(1))?'10px':'0'),width:(activeArr.includes(4)?'52px':'52px')}" @click="activeArea({id:4,areaType:5})">이시카와</span>
                </div>
                <div class="area-title-item1" v-show="this.active===1">
                  <span :style="{backgroundColor:((activeArr.includes(6) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(6) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(6) || activeArr.includes(1))?'10px':'0')}" @click="activeArea({id:6,areaType:4})">산인 산요</span>
                </div>
              </div>
              <div class="area-title1">
                <div class="area-title-item2">
                  <span :style="{backgroundColor:((activeArr.includes(5) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(5) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(5) || activeArr.includes(1))?'10px':'0')}" @click="activeArea({id:5,areaType:3})">시가·효고·와카야마</span>
                </div>
              </div>
            </div>
          </div>
          <div class="facilities-item-box">
            <div class="col-md-12" v-for="(item,i) in showList" :key="i" @click="openSmall(item)">
              <div class="facilities-item" v-if="active=== 1">
                <el-image class="facilities-item-img" :src="item.img" lazy>
                  <div slot="placeholder" class="image-slot">
                    <img :src="loadImg" class="loadImg" alt="" />
                  </div>
                </el-image>
                <div class="facilities-item-title">
                  <p style="width: 293px;margin-bottom: 0;;" v-for="(item ,index) in splitBatName(item.ticketName3)" :key="index">
                    {{item}}</p>
                </div>
              </div>
              <div class="facilities-item" v-else v-show="active === 2 && item.activeId === 1">
                <el-image class="facilities-item-img" :src="item.img" lazy>
                  <div slot="placeholder" class="image-slot">
                    <img :src="loadImg" class="loadImg" alt="" />
                  </div>
                </el-image>
                <div class="facilities-item-title">
                  <p style="width: 293px;margin-bottom: 0;;" v-for="(item ,index) in splitBatName(item.ticketName3)" :key="index">
                    {{item}}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- 底部底图 -->
          <div class="language-bg-box">
            <div class="demo-image__lazy">
              <el-image class="language-bg" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/small_bg.png" style="width: 100%;"></el-image>
            </div>
          </div>
        </div>
        <!-- 观光设施详情页 -->
        <el-drawer title="" :append-to-body="true" :visible.sync="innerDrawer">
          <div class="touch" @touchstart='touchstart' @touchmove='touchmove2'>
            <el-carousel height="210px" :interval="2000" ref="nop" arrow="never">
              <el-carousel-item v-for="(item,index) in ticketDetials.tickeImg" :key="index">
                <img :src="item" style="height:100%;width:100%;" lazy />
              </el-carousel-item>
              <!-- </div> -->
            </el-carousel>
            <div class="main-facility">
              <div class="app-container">
                <p class="travel-copyright" v-if="ticketDetials.copyright">{{ticketDetials.copyright}}</p>
                <div class="col-md-12">
                  <div class="facility-title">{{ticketDetials.areaNAme}}</div>
                </div>
                <div class="col-md-12 facility-title2-box">
                  <div class="facility-title2">
                    <p v-for="(item ,index) in splitBatName(ticketDetials.ticketName4)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12  facility-travel-box">
                  <el-image class="facility-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/facility_area.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <p class="facility-travel-p">
                    <a :href="ticketDetials.Http" target=_ blank>{{ticketDetials.Http}}</a>
                    <a :href="ticketDetials.Http2" target=_ blank>{{ticketDetials.Http2}}</a>
                  </p>
                  <!-- <p class="facility-travel-p" style="margin-left: 0;color: #000;">{{ticketDetials.Http2}}</p> -->
                </div>
                <div class="col-md-12  facility-travel-box">
                  <el-image class="facility-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-facilities.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.Faci)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12  facility-travel-box">
                  <el-image class="facility-travel-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-cart.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.Doer)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12  facility-travel-box">
                  <el-image class="facility-travel-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-name.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.time)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12 facility-travel-box">
                  <el-image class="facility-travel-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-calendar.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.Calendar)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12 facility-travel-box">
                  <el-image class="facility-travel-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-price.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <p class="facility-travel-p">￥{{ticketDetials.price}}</p>
                </div>
                <div class="col-md-12 facility-travel-box" v-if="ticketDetials.content2">
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.content2)" :key="index">{{item}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-drawer>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import topMb from "@/components/top-all/top-Mball4.vue";
export default {
  components: { topMb },
  data() {
    return {
      banH: "",
      active: 1,
      animateTime: 3000, // 要和 css 的过渡一致
      ticketDescribe: "관광시설 공통권 (29시설)",
      planName: ["간사이 와이드 에리어 패스", "5 Days 세트 플랜"],
      planPrice: "27,700",
      planName2: ["간사이 히로시마 에리어 패스", "5 Days 세트 플랜"],
      planPrice2: "32,000",
      planName3: "JR서일본 전지역 패스",
      planPrice3: "39,000",
      ticketDetials: {},
      showDialog: false,
      innerDrawer: false,
      drawer: false,
      startX: "",
      startY: "",
      moveY: "",
      moveX: "",
      startTime: "",
      activeArr: [1],
      activeAreaArr: [],
      showList: [],
      loadImg: require("@/assets/images/hfkansai/loading.png"),
      copyright: require("@/assets/images/allpass/copyright.png"),
      bannerMb: [
        "https://qbcdn.jtrchina.com/tripelletexploreallpass/banner/mb_banner.png",
        require("@/assets/images/allpass/MB_ch.png"),
      ],
      ticketArr: [
        {
          id: 1,
          type: 1,
          fontcolor: "#F98304",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-12.png",
          ticketName: "서일본 탐방권",
          validityDay: 4,
          price: "21,000~",
          describe: [
            "JR 승차권 + ",
            "유니버설 스튜디오 재팬 1 DAY 스튜디오 패스 + ",
            "관광시설 공통권 (29시설)",
          ],
        },
        {
          id: 2,
          type: 2,
          fontcolor: "#F4B22D",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-22.png",
          ticketName: "간사이 탐방권",
          validityDay: 3,
          price: "16,000~",
          describe: [
            "JR 승차권 + ",
            "유니버설 스튜디오 재팬 1 DAY 스튜디오 패스 + ",
            "관광시설 공통권 (16시설)",
          ],
        },
      ],
      scenicSpotArr: [
        // 京都
        {
          id: 1,
          activeId: 1,
          areaNAme: "교토",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_A.jpg",
          ticketName: ["교토 철도 박물관 입장권 "],
          ticketName3: ["교토 철도 박물관 입장권 "],
          ticketName4: ["교토 철도 박물관 입장권 "],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_D.jpg",
          ],
          Doer: ["우메코지 교토니시역에서 도보 2분"],
          time: ["10:00 ～ 17:00 (최종입장 16:30)"],
          Http: "http://www.kyotorailwaymuseum.jp/kr/",
          Faci: ["京都府京都市下京区観喜寺町", "교토 철도 박물관 티켓판매소"],
          Calendar: ["매주 수요일, 연말연시 (임시휴일 있음)"],
          areaType: 1,
          price: "1,200",
        },
        {
          id: 2,
          activeId: 1,
          areaNAme: "교토",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_A.jpg",
          ticketName: ["도에이 우즈마사 영화촌 입장권"],
          ticketName3: ["도에이 우즈마사 영화촌 입장권"],
          ticketName4: ["도에이 우즈마사 영화촌 입장권"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_D.jpg",
          ],
          Doer: ["JR 하나조노역에서 도보 약13분"],
          time: [
            "10:00 ～ 17:00",
            "※ 임시휴업이 있을 수 있으므로, 공식사이트에서 영업시간을 확인해 주세요",
          ],
          Http: "http://www.toei-eigamura.com/global/ko/",
          Faci: [
            "日本京都市右京区太秦東峰岡町10",
            "도에이 우즈마사 영화촌 티켓판매소",
          ],
          Calendar: ["홈페이지에서 확인"],
          areaType: 1,
          price: "2,400",
        },
        {
          id: 3,
          activeId: 1,
          areaNAme: "교토",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_A.jpg",
          ticketName: ["아라시야마 미식투어 쿠폰", "(8점포 중 3곳 선택)"],
          ticketName3: ["아라시야마 미식투어 쿠폰", "(8점포 중 3곳 선택)"],
          ticketName4: ["아라시야마 미식투어 쿠폰", "(8점포 중 3곳 선택)"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_D.jpg",
          ],
          Doer: ["JR 사가아라시야마역에서 도보 8분"],
          time: ['란덴 "아라시야마역 안내소" 09:00 ～ 18:00'],
          Http: "https://www.tripellet.com/gurumeguri_map2022.pdf",
          Http2: "https://www.tripellet.com/gurumeguri2022.pdf",
          Faci: ["京都府京都市右京区嵯峨天龍寺", "造路町20－2（嵐電嵐山駅）"],
          Calendar: ["※ 각 매장의 영업시간은 실물", "쿠폰에서 확인해 주세요."],
          areaType: 1,
          price: "1,000",
          isHttp: 1,
        },
        {
          id: 4,
          activeId: 1,
          areaNAme: "교토",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_A.jpg",
          ticketName: ["Sky Hop Bus", "교토순환버스"],
          ticketName3: ["Sky Hop Bus", "교토순환버스"],
          ticketName4: ["Sky Hop Bus", "교토순환버스"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_C.jpg",
          ],
          Doer: ["JR 교토역 (가라스마 출구) 도보 1분"],
          time: ["웹사이트를 참고해 주십시오(임시휴일 있음)"],
          Http: "https://skyhopbus.com/kyoto/",
          Faci: ["京都市下京区烏丸通塩小路下ル東塩小路町", "교토역 티켓판매소"],
          Calendar: ["홈페이지에서 확인"],
          areaType: 1,
          price: "2,500",
        },
        {
          id: 5,
          activeId: 1,
          areaNAme: "교토",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_A.jpg",
          ticketName: ["유메야카타 기모노렌탈샵 고조점"],
          ticketName3: ["유메야카타 기모노렌탈샵 고조점"],
          ticketName4: ["유메야카타 기모노렌탈샵 고조점"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_D.jpg",
          ],
          Doer: ["JR 지하철 카라스마선 고조역에서 도보3분"],
          time: ["10:00  ～ 17:30（ 최종입장 16:00）"],
          Http: "https://www.kr-kyoto.yumeyakata.com/",
          Faci: ["京都市下京区塩竈町353"],
          Calendar: ["연말연시(12/31 ～ 1/3)"],
          areaType: 1,
          price: "3,850",
        },

        {
          id: 6,
          areaNAme: "교토",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_A.jpg",
          ticketName: ["아마노하시다테", " 이네 2 DAY 프리티켓"],
          ticketName3: ["아마노하시다테", " 이네 2 DAY 프리티켓"],
          ticketName4: ["아마노하시다테", " 이네 2 DAY 프리티켓"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_D.jpg",
          ],
          Doer: ["교토 단고 철도 아마노하시다테역 내"],
          time: ["교환시간 10:00 ～ 16:30", "(아마노하시다테역 관광안내소)"],
          Http: "https://www.tankai.jp/tourist_tickets/",
          Faci: [
            "京都府宫津市字文珠314-2",
            '아마노하시다테역 안내소 "아마노하시다테 관광협회"',
          ],
          Calendar: ["연중 무휴"],
          areaType: 1,
          price: "2,600",
        },
        // 大阪
        {
          id: 7,
          activeId: 1,
          areaNAme: "오사카",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_A.jpg",
          ticketName: ["우메다 스카이빌딩", "공중정원 전망대 입장권"],
          ticketName3: ["우메다 스카이빌딩", "공중정원 전망대 입장권"],
          ticketName4: ["우메다 스카이빌딩", "공중정원 전망대 입장권"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_D.jpg",
          ],
          Doer: ["JR 오사카역에서 도보 7분"],
          time: ["9:30 ～ 22:30（최종입장:22:00）"],
          Http: "https://www.skybldg.co.jp/ko/",
          Faci: ["大阪市北区大淀中1-1-88", "(우메다 스카이빌딩 39층 프론트)"],
          Calendar: ["연중 무휴"],
          areaType: 2,
          price: "1,500",
        },
        {
          id: 8,
          activeId: 1,
          areaNAme: "오사카",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_A.jpg",
          ticketName: ["아베노 하루카스 "],
          ticketName3: ["아베노 하루카스 "],
          ticketName4: ["아베노 하루카스 "],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_D.jpg",
          ],
          Doer: ["JR 덴노지역 근처"],
          time: ["9:00 ～ 22:00  (최종입장:19:30)"],
          Http: "https://www.abenoharukas-300.jp/index.html",
          Faci: ["大阪市阿倍野区阿倍野筋1-1-43", "(16층 안내소)"],
          Calendar: ["연중 무휴"],
          areaType: 2,
          price: "1,500",
        },
        {
          id: 9,
          activeId: 1,
          areaNAme: "오사카",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_A1.jpg",
          ticketName: ["오사카 그란비아 호텔", '"티 라운지"디저트 세트'],
          ticketName3: ['오사카 그란비아 호텔 "티 라운지"디저트 세트 '],
          ticketName4: ["오사카 그란비아 호텔", '"티 라운지"디저트 세트 '],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_A1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_B1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_C1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_D1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_E1.jpg",
          ],
          Doer: ["JR 오사카역 중앙출구 직결"],
          time: [
            "디저트세트 제공시간 : 11:00-16:00",
            "레스토랑 영업시간",
            "평일 10:00-20:00 (L.O. 19:30)",
            "토요일 09:30-20:00 (L.O. 19:30)",
            "일요일 /공휴일 09:30-18:00 (L.O. 17:30)",
          ],
          Http: "https://www.hotelgranviaosaka.jp/ko/dining/lobby-lounge/",
          Faci: ["大阪市北区梅田3丁目1-1", '그란비아 호텔 1층"티 라운지"'],
          Calendar: ["연중 무휴"],
          areaType: 2,
          price: "2,300",
          content: "",
          content2: [
            "※사진은 이미지입니다.시즌에 따라",
            "※식사내용이 변경될 수 있습니다.",
          ],
        },
        {
          id: 10,
          activeId: 1,
          areaNAme: "오사카",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_A.jpg",
          ticketName: ["오사카 수상버스"],
          ticketName3: ["오사카 수상버스"],
          ticketName4: ["오사카 수상버스"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_D.jpg",
          ],
          Doer: ['JR 칸조선 "오사카조코엔역" 에서 도보 3분'],
          time: [
            "출항시간 11:00 / 11:45 / 12:30",
            "13:15 / 14:00 / 14:45 / 15:30 / 16:15",
          ],
          Http: "http://suijo-bus.osaka",
          Faci: [
            "大阪市中央区大阪城2番地先",
            "(수상버스 선착장 오사카성항 승선권 판매소)",
          ],
          Calendar: [
            "평일 주2회, 7/25 오후 배편, 1월중순",
            "2월상순에 운휴일있습니다.",
          ],
          areaType: 2,
          price: "1,600",
          content: "",
          content2: ["봄 시즌(3월하순~4월상순)은 특별요금이 적용됩니다."],
        },
        {
          id: 11,
          activeId: 1,
          areaNAme: "오사카",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_A.jpg",
          ticketName: ["오사카 주택 박물관", "입장권"],
          ticketName3: ["오사카 주택 박물관 입장권"],
          ticketName4: ["오사카 주택 박물관 입장권"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_C.jpg",
          ],
          Doer: ["JR 덴마역에서 하차 후 도보로 약 7분"],
          time: ["10:00 ～ 17:00 (최종입장 16:30)"],
          Http: "http://konjyakukan.com/index.html",
          Faci: [
            "大阪市北区天神橋6丁目4-20",
            "(주거정보센터빌딩 8층 오사카 주택박물관)",
          ],
          Calendar: ["화요일, 연말연시 （ 12/28 ～ 1/4 ）"],
          areaType: 2,
          price: "400",
        },
        // 滋賀・兵庫・和歌山
        {
          id: 12,
          activeId: 1,
          areaNAme: "효고",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_A1.jpg",
          ticketName: ["고베 누노비키 허브 가든 ", "로프웨이 입장권 "],
          ticketName3: ["고베 누노비키 허브 가든 &", "로프웨이 입장권 "],
          ticketName4: ["고베 누노비키 허브 가든 &", "로프웨이 입장권 "],
          Doer: ["JR 신코베역에서 도보 5분"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_A1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_B1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_C1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_D1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_E1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_F1.jpg",
          ],
          time: ["(산로쿠역 4F 안내소) 9:30 ～ 16:45"],
          Http: "https://www.kobeherb.com/kr/",
          Faci: ["神户市中央区北野町1-4-3", "(산로쿠역 4층 프론트)"],
          Calendar: ["공식사이트에서 확인해 주세요."],
          areaType: 3,
          price: "1,800",
        },
        {
          id: 13,
          activeId: 1,
          areaNAme: "효고",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_A.jpg",
          ticketName: ["아카시 주변 스시집", "할인쿠폰'기예의 일품'"],
          ticketName3: ["아카시 주변 스시집 할인쿠폰 '기예의 일품'"],
          ticketName4: ["아카시 주변 스시집 할인쿠폰 '기예의 일품'"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_D.jpg",
          ],
          Doer: ["JR 아카시역"],
          time: ["9:00 ～ 19:00(평일)", "9:00 ～ 18:00(주말/공휴일)"],
          Http: "https://www.yokosoakashi.jp/sites/default/files/images/access/img/akashi_meguri_han.pdf",
          Faci: [
            "兵庫県明石市大明石町1-1-23",
            "(JR아카시역 구내 아카시안내소)",
          ],
          Calendar: ["연말연시（12/31 ～ 1/1 ）"],
          areaType: 3,
          price: "2,000",
        },
        {
          id: 14,
          activeId: 1,
          areaNAme: "효고",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_A.jpg",
          ticketName: ["효고현 아카시", "시립 천문과학관 입장권"],
          ticketName3: ["효고현 아카시 시립 천문과학관 입장권"],
          ticketName4: ["효고현 아카시 시립", "천문과학관 입장권"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_D.jpg",
          ],
          Doer: [
            "JR 아카시역에서 도보 약15분,",
            "산요전철 히토마루마에역에서 도보 약3분",
          ],
          time: [
            "아카시 안내소 :",
            "(평일) 09:00 ～ 19:00",
            "(주말/공휴일) 09:00 ～ 18:00 ",
            "아카시 시립 천문과학관:",
            "09:30 ～ 17:00(최종입장 16:30)",
          ],
          Http: "https://www.am12.jp/",
          Faci: [
            "교환장소 ：兵库县明石市",
            "大明石町1-1-23 piole明石西館",
            "시설주소 ：兵庫県明石市人丸町2-6",
          ],
          Calendar: ["연말연시, ※임시휴관있음"],
          areaType: 3,
          price: "700",
        },
        {
          id: 15,
          areaNAme: "효고",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
          ticketName: ["JENOVA호 아와지섬"],
          ticketName3: ["JENOVA호 아와지섬"],
          ticketName4: ["JENOVA호 아와지섬"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_C.jpg",
          ],
          Doer: ["JR 아카시역에서 도보 약 8분"],
          time: [
            "(평일)",
            "아카시 승선시간 ： 9:00 ～ 17:00",
            "이와야 승선시간 ： 9:00 ～ 17:00",
            "(주말/공휴일)",
            "아카시 승선시간 ： 9:00 ～ 17:00",
            "이와야 승선시간 ： 9:00 ～ 17:00",
            "※자세한 시간표는 공식사이트에서 확인해 주세요.",
          ],
          Http: "http://www.jenova-line.co.jp/jikoku.php",
          Faci: [
            "아카시 승선장 ：兵庫県明石市本町2-10-1",
            "이와야 승선장 ：兵庫県淡路市岩屋925-27",
            "※원하시는 승선장에서 ZENOVA를 이용할 수 있습니다.",
          ],
          Calendar: ["기상 조건이 나쁠 경우 결항될 수있습니다 "],
          areaType: 3,
          price: "530",
        },
        {
          id: 16,
          activeId: 1,
          areaNAme: "시가",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_A.jpg",
          ticketName: ["나가하마 낭만 패스포트", "(시내 관광시설 입장권) "],
          ticketName3: ["나가하마 낭만 패스포트", "(시내 관광시설 입장권) "],
          ticketName4: ["나가하마 낭만 패스포트", "(시내 관광시설 입장권) "],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_D.jpg",
          ],
          Doer: ["JR 나가하마역 내"],
          time: ["나가하마역 관 광안내소：9:15 ～ 16:45"],
          Http: "https://kitabiwako.jp/spot/spot_8966",
          Faci: ["長浜市北船町1番5號", "(나가하마역 관광안내소)"],
          Calendar: ["연말연시"],
          areaType: 3,
          price: "1,200",
        },
        {
          id: 17,
          activeId: 1,
          areaNAme: "시가",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_A.jpg",
          ticketName: [
            "레이크 비와 오쓰 프린스호텔 37층 레이크뷰 식당",
            "'Biona' 런치뷔페",
          ],
          ticketName3: [
            "레이크 비와 오쓰 프린스호텔",
            "37층 레이크뷰 식당 'Biona' 런치뷔페",
          ],
          ticketName4: [
            "레이크 비와 오쓰 프린스호텔 37층 레이크뷰 식당",
            "'Biona' 런치뷔페",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_E.jpg",
          ],
          Doer: ["JR 오쓰역(大津駅)에서 무료 셔틀버스로 약10분"],
          time: [
            "영업시간： 11:30 ～ 15:00",
            "식사시간： 11:30 ～ 13:00 / 13:30 ～ 15:00",
            "（시간제한 90분, 두가지 시간대）",
          ],
          Http: "https://www.princehotels.co.jp/otsu/restaurant/biona/",
          Faci: [
            "滋賀県大津市におの浜4-7-7",
            "びわ湖大津プリンスホテル",
            "(37층 레이크 뷰 식당‘Biona’)",
          ],
          Calendar: ["홈페이지에서 확인"],
          areaType: 3,
          price: "3,000",
        },
        {
          id: 18,
          activeId: 1,
          areaNAme: "시가",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_A.jpg",
          ticketName: ["비와호 치쿠부섬 크루즈", "※히코네 항~치쿠부시마(왕복)"],
          ticketName3: [
            "비와호 치쿠부섬 크루즈",
            "※히코네 항~치쿠부시마(왕복)",
          ],
          ticketName4: [
            "비와호 치쿠부섬 크루즈",
            "※히코네 항~치쿠부시마",
            "(왕복)",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_D.jpg",
          ],
          Doer: [
            "JR히코네역에서 무료 셔틀버스를 타고 약 8분",
            '"히코네코" 정류장에서 하차',
          ],
          time: [
            "3월 1일 ～ 12월 첫째주까지는 매일, 그 다음 날부터 2 월 말까지는 주말과 공휴일만",
          ],
          Http: "http://www.ohmitetudo.co.jp/marine/time-table_hikone-chikubushima-hikone_normal/index.html/",
          Faci: ["滋賀県彦根市松原町3755", "(히코네항 탑승장)"],
          Calendar: ["주말/공휴일 운항", "(평일, 12/30, 12/31은 운휴)"],
          areaType: 3,
          price: "3,000",
        },
        {
          id: 19,
          areaNAme: "시가",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_A.jpg",
          ticketName: ["하치만야마 로프웨이"],
          ticketName3: ["하치만야마 로프웨이"],
          ticketName4: ["하치만야마 로프웨이"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_D.jpg",
          ],
          Doer: [
            "JR오미하치만역 북쪽출구 6번승강장에서 초메지 방향으로 가는 버스를 타고 약7분, 오스키초 하치만야마 로프웨이구치 정거장에서 하차 ",
          ],
          time: ["09:00 ～ 17:00 （상행방향 마지막 운행 16:30）"],
          Http: "http://www.ohmitetudo.co.jp/hachimanyama/open-price/index.html/",
          Faci: [
            "滋賀県近江八幡市宮內町",
            "(하치만야마 로프웨이 산로쿠 티켓판매소)",
          ],
          Calendar: ["연중 무휴"],
          areaType: 3,
          price: "890",
        },
        {
          id: 20,
          areaNAme: "와카야마",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_A.jpg",
          ticketName: ["구시모토 마린파크 수족관 전망대 입장권"],
          ticketName3: ["구시모토 마린파크 수족관 전망대 입장권"],
          ticketName4: ["구시모토 마린파크 수족관 전망대 입장권"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_E.jpg",
          ],
          Doer: ["JR 구시모토역에서 무료 셔틀 버스를타고 12분"],
          time: ["9:00 ～ 16:30(매표소는 16:00까지)"],
          Http: "http://kushimoto.co.jp/english/",
          Faci: [
            "和歌山県東牟婁郡串本町有田1157",
            "(구시모토 마린 파크 수족관 티켓판매소)",
          ],
          Calendar: ["연중 무휴"],
          areaType: 3,
          price: "1,800",
        },
        {
          id: 21,
          areaNAme: "와카야마",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_A.jpg",
          ticketName: ["와카야마 기시가와선", "1일승차권"],
          ticketName3: ["와카야마 기시가와선 1일승차권"],
          ticketName4: ["와카야마 기시가와선 1일승차권"],
          copyright: "「DESIGNED BY EIJI MITOOKA+DON DESIGN ASSOCIATES」",
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_E.jpg",
          ],
          Doer: ["오시는 길: JR 와카야마역 9번 승강장"],
          time: [
            "차량종류에 따라 운행시각이 다릅니다.",
            "운휴일 및 고양이 역장 타마의 근무일, 근무시간은",
            "웹사이트에서 확인해 주세요.",
          ],
          Http: "http://www2.wakayama-dentetsu.co.jp/pdf/wakayama_korean.pdf",
          Faci: ["和歌山県和歌山市美园町5丁目2", "(와카야마역)"],
          Calendar: ["홈페이지에서 확인"],
          areaType: 3,
          price: "800",
        },
        // 山陽山陰
        {
          id: 22,
          areaNAme: "오카야마",
          img: require("@/assets/images/allpass/erdao_A.jpg"),
          ticketName: ["고지마 순환버스 승차권", "및 데님만들기 체험"],
          ticketName3: ["고지마 순환버스 승차권 및 데님만들기 체험"],
          ticketName4: ["고지마 순환버스 승차권 및 데님만들기 체험"],
          tickeImg: [
            require("@/assets/images/allpass/erdao_A.jpg"),
            require("@/assets/images/allpass/erdao_B.jpg"),
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/erdao_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/erdao_D.jpg",
          ],
          Doer: ["JR고지마역에서 버스로 약20분"],
          time: [
            "교환장소 : 09:00 ～ 17:00,",
            "베티스미스 : 09:00 ～ 18:00",
            "(체험시간 11:00 ～,14:00 ～)",
            "고지마 청바지버스 : ",
            "금, 토, 일, 공휴일후레아이 버스",
            "도코하이 버스(매일운행)",
          ],
          Http: "http://www.shimoden.net/rosen/kikaku/jeans.html",
          Faci: ["岡山県倉敷市児島駅前1丁目107", "(JR고지마역 개찰구)"],
          Calendar: [
            "청바지버스 (금, 주말, 공휴일 운행",
            "※연말연시제외)",
            "후레아이호/토코하이호 (매일운행)",
            "베티스미스 (연말연시휴업)",
          ],
          areaType: 4,
          price: "1,000",
        },
        {
          id: 23,
          areaNAme: "오카야마",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_A.jpg",
          ticketName: ["오카야마 그란비아 호텔「Lumiere」과일파르페"],
          ticketName3: ["오카야마 그란비아 호텔「Lumiere」과일파르페"],
          ticketName4: ["오카야마 그란비아 호텔「Lumiere」과일파르페"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_D.jpg",
          ],
          Doer: ["JR오카야마역 근방"],
          time: ["12:00 ～ 17:00(호텔 1층 로비)"],
          Http: "https://granvia-oka.co.jp/ko/restaurant/#lum",
          Faci: [
            "岡山県岡山市北区駅元町1-5",
            "ホテルグランヴィア岡山",
            "(호텔 1층「Lumiere」)",
          ],
          Calendar: ["연중 무휴"],
          areaType: 4,
          price: "2,500",
        },
        {
          id: 24,
          areaNAme: "히로시마",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_A.jpg",
          ticketName: ["굴 어장 소개 및", "이쓰쿠시마신사 해상유람"],
          ticketName3: ["굴 어장 소개 및 이쓰쿠시마신사 해상유람"],
          ticketName4: ["굴 어장 소개 및 이쓰쿠시마신사 해상유람"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_D.jpg",
          ],
          Doer: [
            "산요본선 JR미야지마역에서",
            "도보 약15분",
            "（ 크루즈 승선장에서 도보 약10분）",
          ],
          time: ["10:00 ～ 16:00"],
          Http: "http://shimadasuisan.com/taiken-en.html",
          Faci: ["広島県廿日市市宮島口西1-2-6", "(kaki티켓판매소)"],
          Calendar: ["연중 무휴"],
          areaType: 4,
          price: "2,200",
        },
        {
          id: 25,
          areaNAme: "히로시마",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
          ticketName: ["세토다 주유패스"],
          ticketName3: ["세토다 주유패스"],
          ticketName4: ["세토다 주유패스"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
          ],
          Doer: ["JR 오노미치역 내"],
          time: ["오노미치역 관광안내소", "9:00 ～ 18:00"],
          Http: "https://www.ononavi.jp/fan/onotabi/guruseto/",
          Faci: ["廣島縣尾道市東御所町1-1", "(오노미치역 관광안내소)"],
          Calendar: ["12/29 ～ 1/3"],
          areaType: 4,
          price: "4,500",
        },
        {
          id: 26,
          areaNAme: "야마구치",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_A.jpg",
          ticketName: ["야마구치현 싯쵸루ca"],
          ticketName2: ["(호후시내 관광시설", "입장권)"],
          ticketName3: ["야마구치현 싯쵸루ca", "(호후시내 관광시설 입장권)"],
          ticketName4: ["야마구치현 싯쵸루ca", "(호후시내 관광시설 입장권)"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_E.jpg",
          ],
          Doer: ["JR 호후역 중앙광장 내"],
          time: ["호후시 관광안내소：09:00 ～ 18:00"],
          Http: "https://visit-hofu.jp/en/",
          Faci: [
            "山口県防府市江八町1丁目1-1",
            "(JR호후역 구내 호후시 관광안내소)",
          ],
          Calendar: ["연중 무휴"],
          areaType: 4,
          price: "1,200",
        },
        {
          id: 27,
          areaNAme: "시마네",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_A.jpg",
          ticketName: ["일본정원 유시엔"],
          ticketName3: ["일본정원 유시엔"],
          ticketName4: ["일본정원 유시엔"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_E.jpg",
          ],
          Doer: ["JR마쓰에역 6번 승강장에서 버스로 약 50분"],
          time: ["10:00 ～ 17:00 (최종입장 16:30)"],
          Http: "https://www.yuushien.com/language/kr.html",
          Faci: ["松江市八束町波入1260-2", "(유시엔 티켓판매소)"],
          Calendar: ["12/30 ～ 31"],
          areaType: 4,
          price: "1,000",
        },
        // 石川
        {
          id: 28,
          areaNAme: "이시카와",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_A.jpg",
          ticketName: ["카가 전통공예촌", "유노쿠니노모리"],
          ticketName3: ["카가 전통공예촌", "유노쿠니노모리"],
          ticketName4: ["카가 전통공예촌", "유노쿠니노모리"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_D.jpg",
          ],
          Doer: ["JR가가온센역에서 CANBUS 야마마와리선으로 약 40분"],
          Http: "https://www.yunokuni.jp/mori/?lang=kr",
          Faci: ["石川県小松市栗津溫泉Na3-3", "(카가 전통공예촌 티켓판매소)"],
          Calendar: ["※영업일은 WEB사이트에서 확인해 주세요."],
          time: ["9:00 ～ 16:30（ 최종입장 15:30）"],
          areaType: 5,
          price: "550",
        },
        {
          id: 29,
          areaNAme: "이시카와",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_A.jpg",
          ticketName: ["카가 주유버스 CANBUS", "1일승차권"],
          ticketName3: ["카가 주유버스", "CANBUS 1일승차권"],
          ticketName4: ["카가 주유버스", "CANBUS 1일승차권"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_D.jpg",
          ],
          Doer: ["JR카가온센역 근처"],
          time: ["(교환) 마치즈쿠리 카가 주유권 판매소 08:30 ～ 18:00"],
          Http: "http://www.kaga-canbus.jp/english.php",
          Faci: ["石川県加賀市作見町RI 1-3", "(마치즈쿠리 카가 티켓판매소)"],
          Calendar: ["08:30 ～ 18:00, 정기휴일 : 연중무휴"],
          areaType: 5,
          price: "1,100",
        },
      ],
      md3: [
        {
          id: 1,
          name: "모든 지역",
          num: 29,
          areaType: 0,
        },
        {
          id: 2,
          name: "오사카",
          areaType: 2,
        },
        {
          id: 3,
          name: "이시카와",
          areaType: 1,
        },
      ],
      md4: [
        {
          id: 4,
          name: "이시카와",
          areaType: 5,
        },
      ],
      md5: [
        {
          id: 5,
          name: "시가·효고·와카야마",
          areaType: 3,
        },
      ],
      md7: [
        {
          id: 6,
          name: "산인 산요",
          areaType: 4,
        },
      ],
    };
  },
  created() {
    this.showArea();
  },
  mounted() {
    window.addEventListener("resize", this.setbanH, false);
  },
  destroyed() {
    window.removeEventListener("resize", this.setbanH, false);
  },
  methods: {
    // 手指触摸滑动事件
    touchstart(e) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },
    touchmove(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      this.startX - this.moveX <= 0
        ? console.log("你在往右滑")
        : console.log("你在往左滑");
      if (this.startX - this.moveX >= 150) {
        // 右滑触发
        this.drawer = true;
      }
    },
    touchmove1(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      this.startX - this.moveX <= 0
        ? console.log("你在往右滑")
        : console.log("你在往左滑");
      if (this.startX - this.moveX <= -100) {
        // 右滑触发
        this.drawer = false;
      }
    },
    touchmove2(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      this.startX - this.moveX <= 0
        ? console.log("你在往右滑")
        : console.log("你在往左滑");
      if (this.startX - this.moveX <= -100) {
        // 右滑触发
        this.innerDrawer = false;
      }
    },
    loading() {
      this.showBtn = true;
      this.$forceUpdate();
    },
    setbanH() {
      this.$nextTick(() => {
        this.banH = `${this.$refs.imgH[0].height}px`;
      });
    },
    change(item) {
      this.active = item.id;
      if (this.active === 1) {
        this.ticketDescribe = "관광시설 공통권 (29시설)";
        this.planName = ["간사이 와이드 에리어 패스", "5 Days 세트 플랜"];
        this.planPrice = "27,700";
        this.planName2 = ["간사이 히로시마 에리어 패스", "5 Days 세트 플랜"];
        this.planPrice2 = "32,000";
        this.planName3 = "JR서일본 전지역 패스";
        this.planPrice3 = "39,000";
        this.md3[0].num = 29;
        this.showList = this.scenicSpotArr;
        let array = {
          id: 15,
          areaNAme: "효고",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
          ticketName: ["JENOVA호 아와지섬"],
          ticketName3: ["JENOVA호 아와지섬"],
          ticketName4: ["JENOVA호 아와지섬"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_C.jpg",
          ],
          Doer: ["JR 아카시역에서 도보 약 8분"],
          time: [
            "(평일)",
            "아카시 승선시간 ： 9:00 ～ 17:00",
            "이와야 승선시간 ： 9:00 ～ 17:00",
            "(주말/공휴일)",
            "아카시 승선시간 ： 9:00 ～ 17:00",
            "이와야 승선시간 ： 9:00 ～ 17:00",
            "※자세한 시간표는 공식사이트에서 확인해 주세요.",
          ],
          Http: "http://www.jenova-line.co.jp/jikoku.php",
          Faci: [
            "아카시 승선장 ：兵庫県明石市本町2-10-1",
            "이와야 승선장 ：兵庫県淡路市岩屋925-27",
            "※원하시는 승선장에서 ZENOVA를 이용할 수 있습니다.",
          ],
          Calendar: ["기상 조건이 나쁠 경우 결항될 수있습니다 "],
          areaType: 3,
          price: "530",
          content: "",
          content2: "",
        };
        this.id15 = this.showList.findIndex((item) => {
          if (item.id === 14) {
            return true;
          }
        });
        this.showList.splice(14, 0, array);
      } else {
        this.ticketDescribe = "관광시설 공통권 (29시설)";
        this.planName = ["간사이 와이드 에리어 패스", "5 Days 세트 플랜"];
        this.planPrice = "27,700";
        this.planName2 = ["간사이 히로시마 에리어 패스", "5 Days 세트 플랜"];
        this.planPrice2 = "32,000";
        this.planName3 = "JR서일본 전지역 패스";
        this.planPrice3 = "39,000";
        this.md3[0].num = 16;
        this.showList = this.scenicSpotArr;
        let id15 = this.showList.findIndex((item) => {
          if (item.id === 15) {
            return true;
          }
        });
        this.showList.splice(id15, 1);
      }
      this.activeAreaArr = []; //清除已选中的地区id
      this.activeArr = [1]; //切换探游券时 默认全部
    },
    openSmall(item) {
      this.ticketDetials = item;
      this.innerDrawer = true;
    },
    showArea() {
      this.showList = this.scenicSpotArr;
    },
    activeArea(item) {
      let index = 0;
      this.showList = [];
      if (item.areaType === 0) {
        // 选择了所有
        this.activeArr = [1]; //所有
        this.activeAreaArr = [];
        this.active === 1
          ? (this.showList = this.scenicSpotArr)
          : (this.showList = this.scenicSpotArr); //判断是否西日本
        return;
      }
      if (item.areaType !== 0) {
        // 没有选择全部地区并且选的是西日本
        let alldelIndex = this.checkArray(this.activeArr, 1); //找到复选地区中所有地区的下标
        alldelIndex !== -1 ? this.activeArr.splice(alldelIndex, 1) : ""; //如果有所有地区的下标就删除
        let activeArrDelIndex = this.checkArray(this.activeArr, item.id); //查找复选地区是否已经存在
        let activeAreaArrDelIndex = this.checkArray(
          this.activeAreaArr,
          item.areaType
        ); //查找复选地区是否已经存在
        activeArrDelIndex === -1
          ? this.activeArr.push(item.id)
          : this.activeArr.splice(activeArrDelIndex, 1); //如果目前地区存在就删除，不然就加入
        activeAreaArrDelIndex === -1
          ? this.activeAreaArr.push(item.areaType)
          : this.activeAreaArr.splice(activeAreaArrDelIndex, 1); //查找复选地区是否已经存在
        this.scenicSpotArr.forEach((item1) => {
          if (item.areaType === item1.areaType) {
            // 去重
            let newArr = [];
            for (let i = 0; i < this.activeArr.length; i++) {
              newArr.indexOf(this.activeArr[i]) == -1
                ? newArr.push(this.activeArr[i])
                : "";
            }
            this.activeArr = newArr;
          }
        });
        this.activeAreaArr = this.unique(this.activeAreaArr); //景点属性去重
        this.scenicSpotArr.forEach((item11) => {
          this.activeAreaArr.forEach((item12) => {
            item12 == item11.areaType ? this.showList.push(item11) : "";
            item12 == item11.areaType ? console.log(item11) : "";
          });
        });
      } else {
        this.activeArr = [1];
        this.showList = this.scenicSpotArr;
      }
    },
    unique(arr) {
      var result = [];
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] === arr[j]) {
            j = ++i;
          }
        }
        result.push(arr[i]);
      }
      return result;
    },
    checkArray(arr, target) {
      return arr.indexOf(target);
    },
    handleClose(done) {
      done();
    },
    splitBatName(str) {
      return str;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/indexKr.scss";
.touch {
  touch-action: pan-y !important;
}
.loadImg {
  width: 100%;
  height: auto;
}
.main-package {
  z-index: 0 !important;
}
::v-deep .el-drawer__body {
  overflow: auto;
}
::v-deep .el-drawer__container ::-webkit-scrollbar {
  display: none;
}
::v-deep .el-carousel__indicator--horizontal {
  padding: 0 4px;
}

::v-deep .el-drawer {
  width: 100% !important;
}

::v-deep .el-drawer__header {
  display: block;
  color: rgb(255, 152, 0);
  padding: 14px 16px;
  margin-bottom: 0;
  background-color: rgb(255, 237, 147);
  position: relative;
  height: 27px;
  line-height: 27px;
}

::v-deep .el-icon-close:before {
  content: "\e6de";
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #fb7b05;
  border-radius: 20px;
}

::v-deep .el-drawer__close-btn {
  border: 0;
  outline: none;
}
::v-deep .el-carousel__button {
  display: block;
  opacity: 0.48;
  width: 7px;
  height: 7px;
  background-color: #bcbfbf;
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
}

::v-deep .el-carousel__indicator.is-active button {
  width: 7px;
  height: 7px;
  background-color: #ff8033;
  border-radius: 50%;
}

::v-deep .el-carousel__indicators--horizontal {
  bottom: 4%;
  left: 50%;
  transform: translateX(-50%);
}
.area-title {
  width: 327px !important;
  padding: 10px;
  border-radius: 15px;
  background: #fff;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.area-title1 {
  width: 327px;
  display: flex;
  margin-bottom: 13px;
  align-items: center;
  justify-content: center;
}
.area-title1:nth-child(2) {
  margin-bottom: 13px;
}
.area-title1:nth-child(3) {
  margin-bottom: 0;
}
.area-title-item span {
  display: inline-block;
  width: 76px !important;
}
.area-title-item1 span {
  display: inline-block;
  width: 124px;
}
.area-title-item2 span {
  text-align: center;
  display: inline-block;
  width: 250px !important;
}
.area-title-item:nth-child(2) {
  border-right: 0 !important;
}
.area-title-item,
.area-title-item2 {
  width: 95px;
  font-family: "GenSenRoundedTW-M";
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 16.8px;
  text-align: center;
  color: #ff7b00;
  border-right: 1px solid #ff7b00;
  text-align: center;
}
.area-title-item2 {
  border-right: 0;
  width: 340px;
}
.area-title-item1 {
  font-family: "GenSenRoundedTW-M";
  width: 136px !important;
  text-align: center;
}
.riding-bottom-t:nth-child(2) {
  color: #1ea2e6 !important;
}
@media screen and (max-width: 360px) {
  ::v-deep .el-carousel__indicators--horizontal {
    bottom: 19%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>